import { useFormContext, useWatch } from 'react-hook-form';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { ArmageddonInput } from '@features/tournament/ui/components/TournamentSettings/inputs/ArmageddonInput';
import { KnockoutDraftOrder } from '@features/tournament/ui/components/TournamentSettings/inputs/KnockoutDraftOrder';
import { KnockoutGamesCount } from '@features/tournament/ui/components/TournamentSettings/inputs/KnockoutGamesCount';
import { KnockoutTieGamesCount } from '@features/tournament/ui/components/TournamentSettings/inputs/KnockoutTieGamesCount';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

export function KnockoutSettings() {
  const {
    control,
    setValue,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();

  const [system, clockControl, clockControlSettings, knockOutSettings] =
    useWatch({
      name: [
        'system',
        'clockControl',
        'clockControlSettingsPlayer1',
        'knockOutSettings',
      ],
      control,
    });

  if (system !== 'Knockout') return null;

  return (
    <>
      <KnockoutDraftOrder />

      <KnockoutGamesCount />

      <ClockControlInput
        perPlayer={false}
        label="Контроль времени"
        value={{
          clockControl,
          clockControlSettings,
        }}
        onChange={({ clockControl, clockControlSettings }) => {
          setValue('clockControl', clockControl);
          setValue('clockControlSettingsPlayer1', clockControlSettings);
          setValue('clockControlSettingsPlayer2', clockControlSettings);
        }}
        disabled={disabled}
      />

      <KnockoutTieGamesCount />

      <ClockControlInput
        perPlayer={false}
        label="Время"
        labelIndented
        value={{
          clockControl: knockOutSettings.tieSettings.clockControl,
          clockControlSettings:
            knockOutSettings.tieSettings.clockControlSettingsPlayer1,
        }}
        onChange={({ clockControl, clockControlSettings }) => {
          setValue('knockOutSettings.tieSettings.clockControl', clockControl);
          setValue(
            'knockOutSettings.tieSettings.clockControlSettingsPlayer1',
            clockControlSettings,
          );
          setValue(
            'knockOutSettings.tieSettings.clockControlSettingsPlayer2',
            clockControlSettings,
          );
        }}
        disabled={
          disabled ||
          (knockOutSettings.tieSettings.additionalGamesCount < 1 &&
            knockOutSettings.tieSettings.additionalGamesFinalCount < 1)
        }
      />

      <ArmageddonInput
        alwaysEnabled
        value={{
          armageddonEnabled: true,
          armageddonSettings: knockOutSettings.armageddonSettings,
        }}
        onChange={({ armageddonSettings }) => {
          setValue('knockOutSettings.armageddonSettings', armageddonSettings);
        }}
        disabled={disabled}
      />
    </>
  );
}
