import {
  ClockControlSettings,
  KnockOutSettings,
  OneOnOneSettings,
  SeanceSettingsDto,
  SwissSettings,
  Tournament,
  TournamentAdditionalScoreDto,
  UpdateTournamentRequest,
} from '@features/shared/api/typings';
import { gameTimeControlConfig } from '@features/tournament/constants';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

const DEFAULT_CLOCK_CONTROL_RAPID: ClockControlSettings = {
  initialSeconds: gameTimeControlConfig.Rapid.defaultInitialSeconds,
  addSecondsPerMove: gameTimeControlConfig.Rapid.defaultSecondsAdded,
};
const DEFAULT_CLOCK_CONTROL_BLITZ: ClockControlSettings = {
  initialSeconds: gameTimeControlConfig.Blitz.defaultInitialSeconds,
  addSecondsPerMove: gameTimeControlConfig.Blitz.defaultSecondsAdded,
};
const DEFAULT_ONE_ON_ONE_SETTINGS: OneOnOneSettings = {
  gamesCount: 2,
  tieSettings: {
    additionalGamesCount: 0,
    clockControl: 'Rapid',
    clockControlSettingsPlayer1: DEFAULT_CLOCK_CONTROL_RAPID,
    clockControlSettingsPlayer2: DEFAULT_CLOCK_CONTROL_RAPID,
  },
};
const DEFAULT_KNOCKOUT_SETTINGS: KnockOutSettings = {
  gamesPerRound: 2,
  gamesPerFinalRound: 4,
  draftOrder: 'ByElo',
  tieSettings: {
    additionalGamesCount: 2,
    additionalGamesFinalCount: 4,
    clockControl: 'Blitz',
    clockControlSettingsPlayer1: DEFAULT_CLOCK_CONTROL_BLITZ,
    clockControlSettingsPlayer2: DEFAULT_CLOCK_CONTROL_BLITZ,
  },
  armageddonSettings: {
    clockControl: 'Blitz',
    clockControlSettingsPlayer1: {
      initialSeconds: 5 * 60,
      addSecondsPerMove: 0,
    },
    clockControlSettingsPlayer2: {
      initialSeconds: 4 * 60,
      addSecondsPerMove: 0,
    },
  },
};
const DEFAULT_SWISS_SETTINGS: SwissSettings = {
  roundsCount: 1,
};

const DEFAULT_SEANCE_SETTINGS: SeanceSettingsDto = {
  maxPlayers: 10,
  seancerSide: 'White',
};

export function getDefaultValuesFromTournament(
  tournament: Tournament,
): TournamentEditFormValues {
  const {
    startTimestampMs,
    teamModeSettings,
    roundRobinSettings,
    oneOnOneSettings,
    knockOutSettings,
    swissSettings,
    seanceSettings,
  } = tournament;

  return {
    ...tournament,
    teamModeSettings: teamModeSettings || { size: 2 },
    startTime: startTimestampMs ? new Date(startTimestampMs) : null,
    roundRobinSettings: roundRobinSettings || {
      roundsCount: 1,
    },
    oneOnOneSettings: oneOnOneSettings || {
      ...DEFAULT_ONE_ON_ONE_SETTINGS,
    },
    knockOutSettings: knockOutSettings || {
      ...DEFAULT_KNOCKOUT_SETTINGS,
    },
    swissSettings: swissSettings || {
      ...DEFAULT_SWISS_SETTINGS,
    },
    seanceSettings: seanceSettings
      ? {
          maxPlayers: seanceSettings.maxPlayers,
          seancerSide: seanceSettings.seancerSide,
          // FIXME: бэкенд пока игнорирует это поле, плюс в нём слишком много лишнего (UserDto)
          seancer: seanceSettings.seancer ? undefined : undefined,
        }
      : {
          ...DEFAULT_SEANCE_SETTINGS,
        },
  };
}

export function getSubmitValuesFromForm(
  values: TournamentEditFormValues,
): UpdateTournamentRequest {
  const result: UpdateTournamentRequest = {
    ...values,
    mode: values.system === 'Seance' ? 'Private' : values.mode,
    additionalScoreOrder: values.additionalScoreOrder.filter(
      (v) => !!v,
    ) as TournamentAdditionalScoreDto[],
    startTimestampMs: values.startTime
      ? values.startTime.getTime()
      : Date.now() + 10 * 60 * 1000,
  };
  if (result.mode !== 'Team') delete result.teamModeSettings;
  if (result.system !== 'RoundRobin') {
    delete result.roundRobinSettings;
  }
  if (result.system !== 'OneOnOne') delete result.oneOnOneSettings;
  if (result.system !== 'Knockout') delete result.knockOutSettings;
  if (!result.clockControlSettingsPlayer2)
    result.clockControlSettingsPlayer2 = result.clockControlSettingsPlayer1;
  if (result.system !== 'Seance') delete result.seanceSettings;
  if (result.system !== 'Swiss') delete result.swissSettings;

  return result;
}
