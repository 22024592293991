import { Chess, Move } from 'chess.js';
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  PointerEvent,
} from 'react';
import {
  PieceCode,
  Color,
  SquareName,
  PromotionPiece,
} from '@libs/chess/types';
import { usePrev } from '@libs/hooks/usePrev';
import { Props, UserArrow } from '../ChessBoard';
import {
  Action,
  AllowedAction,
  initSelection,
  SelectionFSM,
  SelectionIdle,
  SelectionSelected,
} from './useChessGame.fsm';

/*
    ____,-------------------------------,____
    \   |              Hook             |   /
    /___|-------------------------------|___\
*/

export type Args = {
  side: 'white' | 'black' | 'all' | 'none';
  // uncontrolled:
  initialFen?: string;
  initialSelection?: SquareSelection;
  initialArrows?: UserArrow[];
  // controlled:
  fen?: string;
  selection?: SquareSelection;
  //
  allowPremove?: boolean;
  autoPromotion?: PromotionPiece;
  onMove?: (m: MoveInfo, san: string) => void;
  onPremove?: (m: MoveInfo | null) => void;
};

export type SquareSelection = {
  from: SquareName;
  to: SquareName;
  action: Action;
};

export type Value = {
  reset: (fen?: string) => void;
  move: (move: ExternalMove) => void;

  props: MappedProps;
};

export type MoveInfo = {
  from: SquareName;
  to: SquareName;
  promotion?: PromotionPiece;
};

type ExternalMove = {
  fen: string;
  prevFen: string;
  from: SquareName;
  to: SquareName;
  promotion?: PromotionPiece;
};

type MappedProps = Pick<
  Props,
  | 'position'
  | 'interactive'
  //
  | 'isPremove'
  | 'selectedFrom'
  | 'selectedTo'
  | 'premoveFrom'
  | 'premoveTo'
  | 'moves'
  | 'promotingFrom'
  | 'promotingTo'
  | 'arrows'
  //
  | 'winner'
  //
  | 'onPieceDrag'
  | 'onSquareClick'
  | 'onSquarePointerDown'
  | 'onSquareDrop'
  | 'onPromotion'
  | 'onKeyDown'
  | 'onArrowDrag'
  | 'onArrowPlace'
>;

export function useChessGame({
  side,
  initialFen,
  initialSelection,
  initialArrows,
  fen,
  selection,
  allowPremove = false,
  autoPromotion,
  onMove,
  onPremove,
}: Args): Value {
  if (side === 'all' && allowPremove) {
    throw new Error(
      'useChessGame: allowPremove is not supported together with side=all',
    );
  }

  const prevFen = usePrev(fen);
  const prevSelection = usePrev(selection);
  const fenChanged = prevFen !== fen;
  const selectionChanged = prevSelection !== selection;

  // ------------------------------------------
  // Store and state

  // sic! useState используется только для инициализации и форсирования
  // перерисовки компонента из обработчиков событий, логика же полагается на
  // mutable ref. Избегание useState помогает реализовать аналог controlled
  // components и избежать лишних перерисовок и вещей вроде setState внутри useEffect
  const [initialSnapshot, _redraw] = useState(() => ({
    chess: new Chess(fen ?? initialFen),
    state: initSelection(selection ?? initialSelection),
    arrows: initialArrows,
  }));
  const refSnapshot = useRef(initialSnapshot);

  // ---

  if (fenChanged) {
    if (fen) {
      refSnapshot.current.chess.load(fen);
    } else {
      refSnapshot.current.chess.reset();
    }
  }

  if (fenChanged || selectionChanged) {
    refSnapshot.current.state = initSelection(selection);
    refSnapshot.current.arrows = undefined;
  }

  // ---

  const { chess, state, arrows } = refSnapshot.current;

  const gameOver = chess.isGameOver();
  const turnColor: Color = chess.turn();
  const allow = mapAllowedAction(side, turnColor, allowPremove, gameOver);

  // ---

  //
  // Обернём все необходимые данные в ref для доступа в коллбэках
  //
  const store = {
    side,
    allowPremove,
    autoPromotion,
    state,
    chess,
    allow,
    onMove,
    onPremove,
    updateState: (s: SelectionFSM) => {
      if (refSnapshot.current.state !== s) {
        refSnapshot.current.state = s;
        _redraw({ ...refSnapshot.current });
      }
    },
    updateArrows: (a?: UserArrow[]) => {
      if (refSnapshot.current.arrows !== a) {
        refSnapshot.current.arrows = a;
        _redraw({ ...refSnapshot.current });
      }
    },
  };
  const refStore = useRef(store);
  refStore.current = store;

  // ---

  //
  // Чтобы не городить с независимыми состояниями для selection и premove, используем
  // prevMoveRef для хранения последнего хода
  //

  const isPremove = state.action === 'premove';
  const prevMoveRef = useRef(state);

  if ((state.name === 'selected' && state.action === 'move') || fenChanged) {
    prevMoveRef.current = state;
  }

  // Использовать prevMoveRef при премуве или пока нет своего хода
  // (чтобы был виден ход последний противника)
  const showPrev =
    !fenChanged && (allow === 'premove' || state.name === 'idle');

  // ------------------------------------------
  // Moves and Premoves

  const handleMove = useCallback((move: Move) => {
    const { chess, onMove, onPremove, state, autoPromotion } = refStore.current;
    const m =
      autoPromotion && move.promotion
        ? {
            from: move.from,
            to: move.to,
            promotion: move.promotion as PromotionPiece,
          }
        : {
            from: move.from,
            to: move.to,
            piece: mapMoveToPieceCode(move),
          };

    if (state.action === 'premove') {
      onPremove?.(m);
    } else {
      const san = chess.move(move)?.san;
      onMove?.(m, san);
    }
  }, []);

  // ------------------------------------------
  // Selection

  //
  // Выбор клетки происходит по ponterdown, а вот снятие двухфазное:
  //  - PointerDown: устанавливает флаг (в SelectionTargeting.transition)
  //  - Click: проверяет флаг и отменяет выбор клетки (в SelectionTargeting.transitionCleanup)
  //
  // Без этого при перетаскивании ранее выбранной фигуры выбор сначала снимется,
  // и вернётся при перемещении мышки
  //

  const handlePointerDown = useCallback(
    (s: SquareName, _: unknown, e: PointerEvent) => {
      const { chess, state, updateState, updateArrows, allow, onPremove } =
        refStore.current;

      // сбросить стрелочки при ЛКМ
      if (e.button === 0 && !e.ctrlKey) updateArrows(undefined);

      // игнорировать ЛКМ при зажатом ctrl (это режим рисования стрелочек)
      if (e.ctrlKey) return;

      if (allow === 'none' || e.button !== 0) return;

      let next: SelectionFSM =
        state.name === 'promoting' || state.name === 'selected'
          ? state.toIdle()
          : state;

      // Сбросим premove
      if (state.name === 'selected' && state.action === 'premove') {
        onPremove?.(null);
      }

      // CORNER CASE: клик на туже пешку, которую двигали, но уже в новой клетке
      if (state.name === 'promoting' && state.to === s) {
        updateState(next);
        return;
      }

      switch (next.name) {
        case 'idle':
          next = next.transition({
            from: s,
            allowedAction: allow,
            chess,
          });
          break;
        case 'targeting':
          next = next.transition({
            to: s,
            chess,
            canReset: true,
            canReselect: true,
            moveCallback: handleMove,
            autoPromotion,
          });
          break;
      }

      updateState(next);
    },
    [autoPromotion, handleMove],
  );

  const handleClick = useCallback(() => {
    const { state, updateState: update } = refStore.current;

    if (state.name === 'targeting') {
      update(state.transitionCleanup());
    }
  }, []);

  // ------------------------------------------
  // Drag

  const handleDrag = useCallback((s: SquareName) => {
    const { allow, state, updateState, chess } = refStore.current;

    if (state.name === 'idle') {
      updateState(
        state.transition({
          allowedAction: allow,
          from: s,
          chess,
        }),
      );
      return true;
    }

    return state.name === 'targeting' && state.from === s;
  }, []);

  const handleDrop = useCallback(
    (_from: SquareName, to: SquareName) => {
      const { state, updateState, chess } = refStore.current;

      if (state.name !== 'targeting') return;

      updateState(
        state.transition({
          to,
          chess,
          canReset: false,
          canReselect: false,
          moveCallback: handleMove,
          autoPromotion,
        }),
      );
    },
    [autoPromotion, handleMove],
  );

  // ------------------------------------------
  // Promotion

  const handlePromotion = useCallback((promoteTo: PromotionPiece) => {
    const { state, updateState, chess, onMove, onPremove } = refStore.current;

    if (state.name !== 'promoting') return;

    const move: MoveInfo = {
      from: state.promotion.from,
      to: state.promotion.to,
      promotion: promoteTo,
    };

    // --- PREMOVE

    if (state.action === 'premove') {
      updateState(state.toSelected());
      onPremove?.(move);
      return;
    }

    // --- MOVE

    const san = chess.move({ ...state.promotion, promotion: promoteTo }).san;
    updateState(state.toSelected());
    onMove?.(move, san);
  }, []);

  // ------------------------------------------
  // Keyboard

  const handleKeyboard = useCallback((e: KeyboardEvent) => {
    const { state, updateState, onPremove } = refStore.current;

    if (
      e.code === 'Escape' &&
      state.name !== 'idle' &&
      (state.name !== 'selected' || state.action === 'premove')
    ) {
      updateState(state.toIdle());

      if (state.action === 'premove') {
        onPremove?.(null);
      }
    }
  }, []);

  // ------------------------------------------
  // Arrows

  const handleArrow = useCallback((arrow: UserArrow) => {
    const { updateArrows } = refStore.current;
    const arr = refSnapshot.current.arrows;
    const duplicate = arr?.find(
      (a) => a.to === arrow.to && a.from === arrow.from,
    );

    if (duplicate) {
      const next = arr!.filter((a) => a !== duplicate);
      if (duplicate.color !== arrow.color) {
        next.push(arrow);
      }
      updateArrows(next.length ? next : undefined);
    } else {
      updateArrows(arr ? [...arr, arrow] : [arrow]);
    }
  }, []);

  const handleArrowDrag = useCallback(() => {
    const { state, updateState: update } = refStore.current;

    if (state.name !== 'idle' && state.name !== 'selected') {
      update(state.toIdle());
    }

    return true;
  }, []);

  // ------------------------------------------
  // External commands

  const handleExternalReset = useCallback((fen?: string) => {
    const { updateState, updateArrows, chess } = refStore.current;

    // сбросить стрелочки
    updateArrows(undefined);

    if (fen) {
      chess.load(fen);
    } else {
      chess.reset();
    }

    updateState(new SelectionIdle());
  }, []);

  const handleExternalMove = useCallback(
    ({ from, to, promotion, fen, prevFen }: ExternalMove) => {
      const { chess, state, updateState, updateArrows, allowPremove, side } =
        refStore.current;

      // сбросить стрелочки
      updateArrows(undefined);

      let nextState: SelectionFSM = new SelectionSelected('move', from, to);

      const internalFen = chess.fen().split(' ')[0];
      if (prevFen.split(' ')[0] === internalFen) {
        chess.move({ from, to, promotion });

        //
        // Обработать ситуацию, что во время premove противник сделал ход
        //

        if (state.name === 'targeting' && state.action === 'premove') {
          nextState = new SelectionIdle().transition({
            allowedAction: mapAllowedAction(
              side,
              chess.turn(),
              allowPremove,
              chess.isGameOver(),
            ),
            from: state.from,
            chess: chess,
          });
        }
      } else if (fen.split(' ')[0] !== internalFen) {
        chess.load(fen);
      }

      updateState(nextState);
    },
    [],
  );

  // ------------------------------------------
  // Return

  return {
    move: handleExternalMove,
    reset: handleExternalReset,
    props: {
      position: chess.fen(),
      interactive: mapInteractive(allow, turnColor),
      isPremove,
      selectedFrom: !showPrev ? state.from : prevMoveRef.current.from,
      selectedTo: !showPrev ? state.to : prevMoveRef.current.to,
      premoveFrom: isPremove ? state.from : undefined,
      premoveTo: isPremove ? state.to : undefined,
      moves: useMemo(() => state.moves?.map((m) => m.to), [state.moves]),
      promotingFrom: state.promotion?.from,
      promotingTo: state.promotion?.to,
      winner: gameOver ? mapWinner(chess) : undefined,
      arrows,
      onPieceDrag: handleDrag,
      onSquareClick: handleClick,
      onSquarePointerDown: handlePointerDown,
      onSquareDrop: handleDrop,
      onPromotion: handlePromotion,
      onKeyDown: handleKeyboard,
      onArrowDrag: handleArrowDrag,
      onArrowPlace: handleArrow,
    },
  };
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function mapAllowedAction(
  side: Args['side'],
  turnColor: Color,
  allowPremove: boolean,
  gameOver: boolean,
): AllowedAction {
  if (gameOver || side === 'none') return 'none';
  if (side === 'all') return 'move';

  const turnSide = turnColor === 'w' ? 'white' : 'black';

  if (side === turnSide) return 'move';
  return allowPremove ? 'premove' : 'none';
}

function mapInteractive(
  allow: AllowedAction,
  turnColor: Color,
): Required<Props>['interactive'] {
  switch (allow) {
    case 'none':
      return 'none';
    case 'move':
      return turnColor === 'w' ? 'white' : 'black';
    case 'premove':
      return turnColor === 'w' ? 'black' : 'white';
  }
}

function mapMoveToPieceCode(move: Move): PieceCode {
  return `${move.color}${move.piece.toLocaleUpperCase()}` as PieceCode;
}

function mapWinner(chess: Chess): 'draw' | 'white' | 'black' {
  if (chess.isDraw()) return 'draw';
  return chess.turn() === 'b' ? 'white' : 'black';
}
