import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TournamentMode } from '@features/shared/api/typings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { NumberInput } from '@ui/components/NumberInput/NumberInput';
import { Tabs } from '@ui/components/Tabs';

const gameModeTabs: {
  value: TournamentMode;
  label: string;
  disabled?: boolean;
}[] = [
  { value: 'Private', label: 'Личный' },
  { value: 'Team', label: 'Командный' },
  {
    value: 'PrivateTeam',
    label: 'Лично-командный',
    disabled: true,
  },
];

export function ModeInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  const system = useWatch({ name: 'system', control });
  const mode = useWatch({ name: 'mode', control });

  let tabs = gameModeTabs;
  if (system === 'OneOnOne')
    tabs = gameModeTabs.filter((t) => t.value !== 'PrivateTeam');
  if (system === 'Swiss') {
    tabs = gameModeTabs.map((t) => ({
      ...t,
      disabled: t.value !== 'Private',
    }));
  }

  return (
    <>
      <FormRow>
        <FormLabel>Тип</FormLabel>
        <FormInput>
          <Controller
            name="mode"
            control={control}
            render={({ field: { value, onChange, disabled } }) => (
              <Tabs
                tabs={tabs}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        </FormInput>
      </FormRow>
      {mode === 'Team' && (
        <FormRow>
          <FormLabel>Игроков в команде</FormLabel>
          <FormInput>
            <Controller
              name="teamModeSettings.size"
              control={control}
              render={({ field: { value, onChange, disabled } }) => (
                <NumberInput
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  min={2}
                  max={12}
                />
              )}
            />
          </FormInput>
        </FormRow>
      )}
    </>
  );
}
