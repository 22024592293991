import { ResultsTableWidget } from 'features/tournament/ui/components/ResultsTable';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import styles from '@features/tournament/pages/TournamentCardPage/TournamentCardPage.module.css';
import { RoundMovementWidget } from '@features/tournament/ui/components/RoundMovement/RoundMovementWidget';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Tabs } from '@ui/components/Tabs';

const COMMON_DESKTOP_RESULTS_TABS = [
  { value: 'table' as const, label: 'Турнирная таблица' },
  { value: 'rounds' as const, label: 'Движение по турам' },
];
const COMMON_MOBILE_RESULTS_TABS = [
  { value: 'table' as const, label: 'Таблица' },
  { value: 'rounds' as const, label: 'По турам' },
];

const KNOCKOUT_RESULTS_TABS = [
  { value: 'table' as const, label: 'Общая таблица' },
  { value: 'rounds' as const, label: 'По этапам' },
];

type Props = {
  clubId: string;
  tournamentId: string;
  resultsTab: 'table' | 'rounds';
  onResultsTabChange: (resultsTab: 'table' | 'rounds') => void;
};

/**
 * Виджет выбирает, показывать таблицу или движение по раундам в результатах
 */
export function TournamentResultsWidget({
  clubId,
  tournamentId,
  resultsTab,
  onResultsTabChange,
}: Props) {
  const isMobile = useMediaQuery() === 'mobile';

  const { data: tournament } = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });

  const isSeance = tournament?.system === 'Seance';
  const isTournament = tournament?.system !== 'OneOnOne' && !isSeance;
  const isKnockoutTournament = tournament?.system === 'Knockout';

  const tabs = isKnockoutTournament
    ? KNOCKOUT_RESULTS_TABS
    : isMobile
      ? COMMON_MOBILE_RESULTS_TABS
      : COMMON_DESKTOP_RESULTS_TABS;
  const roundsOnly = isMobile && isKnockoutTournament;

  const table = (
    <ResultsTableWidget clubId={clubId} tournamentId={tournamentId} />
  );
  const rounds = (
    <RoundMovementWidget clubId={clubId} tournamentId={tournamentId} />
  );

  if (isSeance) {
    return (
      <div className={styles.results}>
        <div className={styles.participantsTitle}>
          <PageSubTitle>Результаты</PageSubTitle>
        </div>

        {rounds}
      </div>
    );
  }

  return (
    <div className={styles.results}>
      <div className={styles.participantsTitle}>
        <PageSubTitle>Участники</PageSubTitle>
        {isTournament && !roundsOnly && (
          <Tabs
            tabClassName={styles.participantsTab}
            tabs={tabs}
            onChange={onResultsTabChange}
            value={resultsTab}
          />
        )}
      </div>

      {resultsTab === 'table' && !roundsOnly && table}
      {(resultsTab === 'rounds' || roundsOnly) && rounds}
    </div>
  );
}
