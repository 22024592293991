import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { KnockoutDraftOrder } from '@features/shared/api/typings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const OPTIONS: {
  label: ReactNode;
  value: KnockoutDraftOrder;
  disabled?: boolean;
}[] = [
  { label: 'Стандартная', value: 'ByElo' },
  { label: 'По порядку', value: 'ByNumberInTournament' },
];

export function KnockoutDraftOrder() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Жеребьёвка</FormLabel>
      <FormInput>
        <Controller
          name="knockOutSettings.draftOrder"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={OPTIONS}
              onChange={onChange}
              value={value}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
