import { useState } from 'react';
import { useNewGameMutation } from '@features/game/api';
import { setClipboard } from '@libs/clipboard';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { Link } from '@tanstack/react-router';
import { ThemeButton } from '@ui/components/ThemeButton';
import styles from './IndexPageTemp.module.css';

/*
    ____,-------------------------------,____
    \   |           Components          |   /
    /___|-------------------------------|___\
*/

export function IndexPageTemp() {
  const mutation = useNewGameMutation();

  const [copied, setCopied] = useState<string | null>(null);

  const { seconds } = useCountdownUntill(
    mutation.data ? mutation.data.startTimestampMs : 0,
    !mutation.data,
  );

  const btnText =
    copied ??
    (mutation.data ? (
      <>
        Starting in <i>{formatTime(seconds)}</i>
      </>
    ) : (
      'New Game'
    ));

  return (
    <div className={styles.layout}>
      {mutation.data && (
        <div className={styles.black}>
          <Link
            id="black"
            to="/game/$gameId"
            params={{ gameId: mutation.data.id }}
            search={{ playerId: mutation.data.black.id }}
          >
            Play Black
          </Link>
          <span
            className={styles.copy}
            onClick={() =>
              setClipboard(getLink('black')).then(() => {
                setCopied('Copied black!');
              })
            }
          >
            📋 copy link
          </span>
        </div>
      )}
      <ThemeButton
        className={styles.btn}
        onClick={() => mutation.mutate()}
        disabled={mutation.isPending || !!mutation.data}
      >
        {btnText}
      </ThemeButton>
      {mutation.data && (
        <div className={styles.white}>
          <Link
            id="white"
            to="/game/$gameId"
            params={{ gameId: mutation.data.id }}
            search={{ playerId: mutation.data.white.id }}
          >
            Play White
          </Link>
          <span
            className={styles.copy}
            onClick={() =>
              setClipboard(getLink('white')).then(() => {
                setCopied('Copied white!');
              })
            }
          >
            📋 copy link
          </span>
        </div>
      )}
    </div>
  );
}

/*
    ____,-------------------------------,____
    \   |           Helpers             |   /
    /___|-------------------------------|___\
*/

function getLink(id: string) {
  const el = document.getElementById(id)! as HTMLLinkElement;
  return el.href;
}

function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return (
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')
  );
}
