import { Fragment, useCallback, useState } from 'react';
import { components } from '@features/shared/api/club/generated';
import {
  ResultsGame,
  ResultsTableParticipant,
  type TournamentSystem,
  TournamentAdditionalScore,
} from '@features/shared/api/typings';
import { GameResultsBadge } from '@features/tournament/ui/components/GameResultBadge';
import { cx } from '@libs/classnames';
import { CollapseIcon } from '@ui/components/CollapseIcon';
import { HorizontalScroll } from '@ui/components/HorizontalScroll';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import styles from './ResultsTable.module.css';

const additionalScoreMap: Record<TournamentAdditionalScore, string> = {
  Berger: 'КБ',
  PartBerger: 'УБ',
  Match: 'ЛВ',
  ScoreSum: 'СО',
  Black: 'РЧ',
  Buchholz: 'КБ',
  PartBuchholz: 'УБ',
};

export type Props = {
  className?: string;
  rows: ResultsTableParticipant[];
  rounds: {
    roundNumber: number;
    type: components['schemas']['RoundType'];
    status: components['schemas']['RoundStatus'];
  }[];
  system: TournamentSystem;
  eventFinished: boolean;
  additionalScoresOrder: TournamentAdditionalScore[];
  onGameClick?: (gameId: string) => void;
};
export function ResultsTable({
  className,
  rows,
  rounds,
  system,
  eventFinished,
  additionalScoresOrder,
  onGameClick,
}: Props) {
  const [visibleGroups, setVisibleGroups] = useState<number[]>([]);
  const isMatch = system === 'OneOnOne';

  const onToggle = useCallback(
    (num: number) => {
      if (visibleGroups.includes(num)) {
        const newArr = [...visibleGroups];
        newArr.splice(visibleGroups.indexOf(num), 1);
        setVisibleGroups(newArr);
      } else {
        setVisibleGroups([num, ...visibleGroups]);
      }
    },
    [visibleGroups, setVisibleGroups],
  );

  if (
    !['RoundRobin', 'OneOnOne', 'Swiss'].includes(system) ||
    !rows[0] ||
    !rows[0].games.length
  )
    return null;

  const roundNames: string[] = [];
  let i = 1;
  let currentType = '';
  rounds.forEach((r) => {
    if (r.type !== currentType) {
      i = 1;
      currentType = r.type;
    }

    if (r.type === 'Normal') {
      roundNames.push('' + i);
    } else if (r.type === 'TieBreak') {
      roundNames.push(`Доп. ${i}`);
    } else if (r.type === 'Armageddon') {
      roundNames.push('Армагеддон');
    }

    i++;
  });

  const roundCount = Math.max(rows[0].games.length, rounds.length);
  const roundIdxs = Array.from(Array(roundCount).keys());

  const isFinished = eventFinished;

  const filteredAdditionalScores = additionalScoresOrder.filter(
    (aS) => aS !== 'Match',
  );

  return (
    <HorizontalScroll enableForDesktop className={cx(className, styles.root)}>
      <Table className={styles.table}>
        <TableRow header>
          <TableCell className={styles.name} colSpan={3}>
            Имя
          </TableCell>

          {roundIdxs.map((i) => (
            <TableCell
              key={roundNames[i] || ++i + '`'}
              className={cx(styles.numbers, styles.top)}
            >
              {roundNames[i] || ++i + '`'}
            </TableCell>
          ))}

          {filteredAdditionalScores.map((s) => (
            <TableCell key={s} right className={styles.score}>
              {additionalScoreMap[s]}
            </TableCell>
          ))}

          <TableCell right className={styles.score}>
            Очки
          </TableCell>
          {!isMatch && (
            <TableCell right className={styles.place}>
              Место
            </TableCell>
          )}
        </TableRow>

        {rows.map((participant) => (
          <Fragment key={participant.title}>
            <ResultsRow
              key={participant.no}
              participant={participant}
              system={system}
              rounds={rounds}
              roundGames={participant.games}
              matchFinished={isFinished}
              isGroup={!!participant.subRows?.length}
              onToggle={onToggle}
              isToggled={visibleGroups.includes(participant.no)}
              additionalScoresOrder={filteredAdditionalScores}
              onGameClick={onGameClick}
            />

            {visibleGroups.includes(participant.no) &&
              participant.subRows?.map((sub) => (
                <ResultsRow
                  key={sub.title}
                  participant={sub}
                  system={system}
                  rounds={rounds}
                  roundGames={sub.games}
                  matchFinished={isFinished}
                  isChild
                  additionalScoresOrder={filteredAdditionalScores}
                  onGameClick={onGameClick}
                />
              ))}
          </Fragment>
        ))}
      </Table>
    </HorizontalScroll>
  );
}

type ResultsRowProps = {
  participant: ResultsTableParticipant;
  system: TournamentSystem;
  rounds: {
    roundNumber: number;
    type: components['schemas']['RoundType'];
    status: components['schemas']['RoundStatus'];
  }[];
  roundGames: ResultsGame[];
  matchFinished: boolean;
  isGroup?: boolean;
  isChild?: boolean;
  onToggle?: (num: number) => void;
  isToggled?: boolean;
  additionalScoresOrder: TournamentAdditionalScore[];
  onGameClick?: (gameId: string) => void;
};
function ResultsRow({
  participant,
  system,
  rounds,
  roundGames,
  isGroup,
  isChild,
  onToggle,
  isToggled,
  matchFinished,
  additionalScoresOrder,
  onGameClick,
}: ResultsRowProps) {
  const isMatch = system === 'OneOnOne';
  const isSwiss = system === 'Swiss';
  const onClick = useCallback(() => {
    onToggle?.(participant.no);
  }, [onToggle, participant]);

  const place: string =
    (participant?.place &&
      matchFinished &&
      ['1', '2', '3'].includes(participant.place.toString()) &&
      'I'.repeat(Number(participant.place))) ||
    participant.place ||
    '';

  const roundCount = Math.max(rounds.length, roundGames.length);
  const roundIdxs = Array.from(Array(roundCount).keys());

  return (
    <TableRow
      className={cx({
        [styles.large]: isMatch || isSwiss,
        [styles.subRow]: isChild,
      })}
      group={isGroup}
      hoverable
    >
      <TableCell
        className={cx(styles.numbers, styles.left, styles.tableNumber)}
      >
        {!isChild && participant.no}
      </TableCell>
      <TableCell className={cx(styles.numbers, styles.left, styles.seedNumber)}>
        {isSwiss && !isChild && participant.seedNumber}
      </TableCell>
      <TableCell onClick={(isGroup && onClick) || undefined}>
        <div className={styles.titleContent}>
          <div className={styles.title}>{participant.title}</div>
          {isGroup && (
            <CollapseIcon className={styles.collapseIcon} toggled={isToggled} />
          )}
        </div>
      </TableCell>

      {roundIdxs.map((i) => {
        const game =
          system === 'Swiss'
            ? roundGames.find((g) => g.roundNo === rounds[i]?.roundNumber)
            : roundGames[i];
        return (
          <Fragment key={game?.gameId + '' + i}>
            <TableCell className={styles.result}>
              <div className={styles.resultContent}>
                <GameResultsBadge
                  gameId={game?.gameId}
                  additionalNumber={game?.opponentSeedNumber}
                  large={system === 'Swiss'}
                  side={game?.side}
                  result={game?.resultType}
                  score={game?.score}
                  onGameClick={onGameClick}
                />
              </div>
            </TableCell>
          </Fragment>
        );
      })}

      {additionalScoresOrder.map((as) => (
        <TableCell key={as} className={styles.score} right>
          {
            participant.additionalScores?.find(
              (s) => s?.scoreType === as || s?.type === as,
            )?.score
          }
        </TableCell>
      ))}

      <TableCell className={styles.totalScore} right>
        {(!isChild || isMatch) && participant.totalScore}
      </TableCell>
      {!isMatch && (
        <TableCell className={styles.place} right>
          {!isChild && (
            <span
              className={cx(styles.placeContent, {
                [styles.winningPlace]:
                  matchFinished &&
                  // FIXME: с бэка не летят римские цифры
                  ['I', 'II', 'III'].includes(place),
              })}
            >
              {place}
            </span>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
