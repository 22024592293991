// Документация:
// - Спека: https://www.saremba.de/chessgml/standards/pgn/pgn-complete.htm
// - Расширения: https://rpb-chessboard.yo35.org/documentation/pgn-syntax/
//
// TODO: из расширений
// - Null moves
// - [%ctl]

type FileName = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h';
type RankName = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
type SquareName = `${FileName}${RankName}`;

/*
    ____,-------------------------------,____
    \   |           Интерфейсы          |   /
    /___|-------------------------------|___\
*/

export interface IMoveDto {
  color?: 'white' | 'black';
  moveFull?: number;
  san: string;
  alts?: IMoveDto[][];
  nags?: number[];
  comment?: string;
  commentBefore?: string;

  // Embedded comands:

  // [%emt 1:30:51]
  timeElapsed?: number;

  // [%clk 1:30:51]
  timeRemaining?: number;

  // [%eval -0.28], [%eval #-5] (мат в 5 ходов), [%eval 1.28,56] (глубина 56)
  score?: IEvaluationScore;

  // [%csl Rd4,Gd5]
  coloredSquares?: IColoredSquare[];

  // [%cal Rc8f5,Ra8d8,Re8c8]
  coloredArrows?: IColoredArrow[];
}

export interface IPgnMove extends IMoveDto {
  id: string;
  color: 'white' | 'black';
  moveFull: number;
  alts?: IPgnMove[][];
}

export interface IColoredSquare {
  color: 'R' | 'G' | 'B';
  square: SquareName;
}

export interface IColoredArrow {
  color: 'R' | 'G' | 'B';
  from: SquareName;
  to: SquareName;
}

export interface IEvaluationScore {
  type: 'cp' | 'mate';
  value: number;
  depth?: number;
}

/*
    ____,-------------------------------,____
    \   |              PGN              |   /
    /___|-------------------------------|___\
*/

export class Pgn<T extends IMoveDto> {
  constructor({ tags, moves }: { tags?: Record<string, string>; moves?: T[] }) {
    this.moves = moves;
    this.rawTags = tags ?? {};
    if (tags) {
      this.event = this.#asStringNotQuestion(tags.Event);
      this.site = this.#asStringNotQuestion(tags.Site);
      this.date = this.#asDateStringNotQuestion(tags.Date);
      this.round = this.#asStringNotQuestion(tags.Round);
      this.white = this.#asStringNotQuestion(tags.White);
      this.black = this.#asStringNotQuestion(tags.Black);
      if (
        tags.Result &&
        tags.Result !== '*' &&
        ['1-0', '0-1', '1/2-1/2'].includes(tags.Result)
      ) {
        this.result = tags.Result as Pgn<T>['result'];
      }
      this.fen = tags.FEN;
      this.whiteTitle = this.#asStringNotDash(tags.WhiteTitle);
      this.blackTitle = this.#asStringNotDash(tags.BlackTitle);
      this.whiteElo = this.#asInteger(tags.WhiteElo);
      this.blackElo = this.#asInteger(tags.BlackElo);
      this.annotator = tags.Annotator;
      this.eventDate = this.#asDateStringNotQuestion(tags.EventDate);
      this.section = tags.Section;
      this.stage = tags.Stage;
      this.board = this.#asInteger(tags.Board);
      this.timeControl = this.#asStringNotQuestion(tags.TimeControl);
      this.variant = tags.Variant;
    }
  }

  // Tag Normalizers

  #asInteger(v?: string): number | undefined {
    if (v && /^\d+$/.test(v)) return parseInt(v, 10);
  }

  #asStringNotQuestion(v?: string) {
    if (v !== '?') return v;
  }

  #asStringNotDash(v?: string) {
    if (v !== '-') return v;
  }

  #asDateStringNotQuestion(v?: string) {
    if (v !== '????.??.??') return v;
  }

  // Raw Tags

  rawTags: Record<string, string>;

  // The "Seven Tag Roster"

  event?: string;
  site?: string;
  date?: string;
  round?: string;
  white?: string;
  black?: string;
  result?: '1-0' | '0-1' | '1/2-1/2';

  // Other Tags

  fen?: string;
  whiteTitle?: string;
  blackTitle?: string;
  whiteElo?: number;
  blackElo?: number;
  annotator?: string;
  eventDate?: string;
  section?: string;
  stage?: string;
  board?: number;
  timeControl?: string | '-'; /* "-" — игра без контроля времени */
  variant?: string;

  // Moves

  moves?: T[];

  // Instance methods

  serialize() {
    // Headers

    const result = [
      // seven tag roster
      `[Event "${this.event ?? '?'}"]`,
      `[Site "${this.site ?? '?'}"]`,
      `[Date "${this.date ?? '????.??.??'}"]`,
      `[Round "${this.round ?? '?'}"]`,
      `[White "${this.white ?? '?'}"]`,
      `[Black "${this.black ?? '?'}"]`,
      `[Result "${this.result ?? '*'}"]`,
    ];
    // other tags
    const addTag = (tag?: string, value?: string | number) => {
      if (value !== undefined)
        result.push(
          `[${tag} ${typeof value === 'string' ? `"${value}"` : value}]`,
        );
    };
    if (this.fen) {
      result.push(`[SetUp "1"]`);
      result.push(`[FEN "${this.fen}"]`);
    }
    addTag('WhiteTitle', this.whiteTitle);
    addTag('BlackTitle', this.blackTitle);
    addTag('WhiteElo', this.whiteElo);
    addTag('BlackElo', this.blackElo);
    addTag('Annotator', this.annotator);
    addTag('EventDate', this.eventDate);
    addTag('Section', this.section);
    addTag('Stage', this.stage);
    addTag('Board', this.board);
    addTag('TimeControl', this.timeControl);
    addTag('Variant', this.variant);

    // Moves

    result.push('');
    result.push(
      (this.moves ? serializeMoveDtos(this.moves) + ' ' : '') +
        (this.result ?? '*'),
    );

    // Result

    return result.join('\n');
  }

  // Static methods

  static parse(pgn: string): Pgn<IPgnMove> | undefined {
    return this.#parse(pgn, true)[0];
  }

  static parseMany(pgn: string): Pgn<IPgnMove>[] {
    return this.#parse(pgn);
  }

  // Helpers

  static #parse(pgn: string, single = false): Pgn<IPgnMove>[] {
    const split = pgn
      .replace(/\r/g, '')
      .split('\n\n')
      .filter((l) => l && !l.startsWith('%'));

    const results: Pgn<IPgnMove>[] = [];

    for (let i = 0; i < split.length; i += 1) {
      const h = split[i]?.trim();
      const m = split[i + 1]?.trim();

      if (!h || !h.startsWith('[') || !m) {
        continue;
      }

      const tags = parsePgnTags(h);
      const moves = PgnMovetextParser.parse(m);
      results.push(new Pgn<IPgnMove>({ tags, moves }));

      if (single) break;

      i += 1;
    }

    return results;
  }
}

/*
    ____,-------------------------------,____
    \   |             Парсеры           |   /
    /___|-------------------------------|___\
*/

function parsePgnTags(source: string): Record<string, string> {
  const result: Record<string, string> = {};

  source.split('\n').forEach((l) => {
    const matches = /\[([\w\d]+)\s+["'](.*?)['"]/.exec(l);

    if (!matches) return;

    const [, name, value] = matches;

    if (!name || !value) return;

    result[name] = value;
  });

  return result;
}

class PgnMovetextParser {
  readonly source: string;
  #at = 0;
  #lastFullMove = 0;
  #lastId = 0;

  private constructor(source: string) {
    this.source = source
      .replace(/\s+/g, ' ')
      .replace(/\*|1-0|0-1|1\/2-1\/2$/g, '');
  }

  static parse(source: string): IPgnMove[] | undefined {
    return new PgnMovetextParser(source).#parse();
  }

  #parse(): IPgnMove[] | undefined {
    const moves: IPgnMove[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const cur = this.#parseMove();
      if (!cur) break;
      moves.push(cur);
    }

    return moves.length ? moves : undefined;
  }

  #parseMove(): IPgnMove | undefined {
    let san: string | undefined;
    let moveFull: number | undefined;
    let color: 'white' | 'black' | undefined;
    let comment: string | undefined;
    let commentBefore: string | undefined;
    let timeRemaining: number | undefined;
    let timeElapsed: number | undefined;
    let coloredSquares: IColoredSquare[] | undefined;
    let coloredArrows: IColoredArrow[] | undefined;
    let score: IEvaluationScore | undefined;
    let alts: IPgnMove[][] | undefined;
    let nags: number[] | undefined;

    while (this.#at < this.source.length) {
      const ch = this.source[this.#at]!;

      // Comments
      if (ch === '{') {
        const c = this.#parseComment();

        if (!san) {
          commentBefore = c?.comment;
        } else {
          comment = c?.comment;
          const commands = c?.commands;

          if (commands) {
            if (commands.clk?.length === 1) {
              timeRemaining = timeToSeconds(commands.clk[0]!);
            }
            if (commands.emt?.length === 1) {
              timeElapsed = timeToSeconds(commands.emt[0]!);
            }
            if (commands.csl) {
              coloredSquares = mapClsSquares(commands.csl);
            }
            if (commands.cal) {
              coloredArrows = mapCalSquares(commands.cal);
            }
            if (commands.eval) {
              score = mapEval(commands.eval);
            }
          }
        }
      }
      // Alternatives
      else if (ch === '(') {
        this.#at++;
        if (san) {
          if (!alts) alts = [];
          const alt = this.#parse();
          if (alt) {
            alts.push(alt);
            // нормализация (flatten):
            // записи "(3. a3) (3. b3) (3. b4)" и "(3. a3 (3. b3 (3. b4)))" —
            // равнозначны
            if (alt[0]?.alts) {
              const flattened = alt[0].alts;
              alts.push(...flattened);
              alt[0].alts = undefined;
            }
          }
        }
      }
      // End of alternatives
      else if (ch === ')') {
        if (san) break;
        this.#at++;
        return undefined;
      }
      // NAGs
      else if (ch === '$') {
        const nag = this.#parseNag();
        if (nag) {
          if (!nags) nags = [];
          nags.push(nag);
        }
      }
      // Move numbers
      else if (/[1-9]/.test(ch)) {
        if (san) break;

        const { num, color: _color } = this.#parseFullNumber();
        color = _color;
        this.#lastFullMove = moveFull = num;
      }
      // SANs
      else if (/\w/.test(ch)) {
        if (san) break;

        san = this.#parseSan() ?? '';
      }
      // Ignore all else
      else {
        this.#at++;
      }
    }

    if (this.#at === this.source.length && !san) {
      if (color || moveFull || comment) {
        console.error('PgnMovetextParser: invalid last move');
      }
      return undefined;
    }

    if (!color) color = 'black';
    if (color === 'black' && !moveFull) {
      moveFull = this.#lastFullMove;
    }

    const result: IPgnMove | undefined =
      san && color && moveFull
        ? {
            id: `pgn-${this.#lastId++}`,
            san,
            moveFull,
            color,
            comment,
            commentBefore,
            timeRemaining,
            timeElapsed,
            coloredSquares,
            coloredArrows,
            score,
            alts,
            nags,
          }
        : undefined;

    if (!result) {
      console.error('PgnMovetextParser: invalid move', this.#at);
    }

    return result;
  }

  #parseFullNumber(): { num: number; color: 'white' | 'black' } {
    let ch = this.source[this.#at];
    let number = '';

    let dots = 0;

    while (ch && /\d|\./.test(ch)) {
      number += ch;

      if (ch === '.') {
        dots++;
      } else if (dots) {
        // после точек номер заканчивается и может начаться рокировка,
        // записанная нулями, типа 4.0-0-0, поэтому остановим обработку
        break;
      }

      this.#at++;
      ch = this.source[this.#at];
    }

    return {
      num: parseInt(number),
      color: number.endsWith('...') ? ('black' as const) : ('white' as const),
    };
  }

  #parseComment():
    | { comment?: string; commands?: Record<string, string[]> }
    | undefined {
    const end = this.source.indexOf('}', this.#at + 1);

    if (end === -1) {
      this.#at = this.source.length;
      return undefined;
    }

    this.#at++;

    let comment = '';
    let commands: Record<string, string[]> | undefined;

    while (this.#at < end) {
      const ch = this.source[this.#at]!;

      if (!comment && ch === ' ') {
        this.#at++;
        continue;
      }

      if (ch === '[' && this.source[this.#at + 1] === '%') {
        const spBefore = this.source[this.#at - 1] === ' ';

        const d = this.#parseCommentCommands();

        if (d) {
          if (!commands) {
            commands = {};
          }
          commands[d[0]] = d[1];
        }

        const spAfter = this.source[this.#at] === ' ';

        if (spBefore && spAfter) this.#at++;
        if (!spBefore && !spAfter) comment += ' ';
      } else {
        comment += ch;
        this.#at++;
      }
    }

    this.#at = end + 1;
    comment = comment.trim();

    if (comment || commands) {
      const result = { comment: comment || undefined, commands };
      return result;
    }
  }

  #parseNag(): number | undefined {
    this.#at++;

    let ch = this.source[this.#at];
    let nagText = '';

    while (ch?.match(/\d/) && this.#at < this.source.length) {
      nagText += ch || '';
      this.#at++;
      ch = this.source[this.#at];
    }

    const nag = parseInt(nagText, 10);

    return nag || undefined;
  }

  #parseCommentCommands(): [string, string[]] | undefined {
    this.#at++;

    const end = this.source.indexOf(']', this.#at);
    let command = this.source.substring(this.#at, end);
    this.#at = end + 1;

    command = command.trim();

    if (command && command.startsWith('%')) {
      const name = command.substring(1, command.indexOf(' '));
      const argsText = name ? command.substring(1 + name.length + 1) : null;
      const args = argsText?.split(/\s*,\s*/);

      if (name && args?.length) {
        return [name, args];
      }
    }
  }

  #parseSan(): string | undefined {
    let ch = this.source[this.#at];
    let san = '';

    while (ch && ch !== ' ' && ch !== ')' && ch !== '(' && ch !== '{') {
      san += ch;
      this.#at++;
      ch = this.source[this.#at];
    }

    // нормализация рокировки
    if (san === '0-0') return 'O-O';
    if (san === '0-0-0') return 'O-O-O';

    return san;
  }
}

/*
    ____,-------------------------------,____
    \   |         Сериализаторы         |   /
    /___|-------------------------------|___\
*/

// Out: PGN movetext
function serializeMoveDtos(moves: IMoveDto[], _initialMove = 1): string {
  const pgn: string[] = [];
  const comments: string[] = [];
  moves.forEach((cur, i) => {
    // half move

    const color =
      cur.color ?? (_initialMove + (i % 2) === 1 ? 'white' : 'black');
    const moveFull =
      cur.moveFull ??
      _initialMove + Math.floor((i + (color === 'white' ? 1 : 0)) / 2);

    if (cur.commentBefore && pgn.length === 0) {
      pgn.push(`{${escapeComment(cur.commentBefore)}}`);
    }

    if (color === 'white') {
      pgn.push(`${moveFull}. ${cur.san}`);
    } else {
      const prev = moves[i - 1];
      const isBroken = prev?.alts || prev?.comment || i === 0;

      if (isBroken) {
        pgn.push(`${moveFull}...`);
      }

      pgn.push(cur.san);
    }

    // NAGs

    if (cur.nags) {
      pgn.push(cur.nags.map((g) => `$${g}`).join(' '));
    }

    // comments

    if (
      cur.comment ||
      cur.timeElapsed ||
      cur.timeRemaining ||
      cur.coloredSquares ||
      cur.coloredArrows
    ) {
      if (cur.timeElapsed) {
        comments.push(`[%emt ${secondsToTime(cur.timeElapsed)}]`);
      }
      if (cur.timeRemaining) {
        comments.push(`[%clk ${secondsToTime(cur.timeRemaining)}]`);
      }
      if (cur.coloredSquares) {
        comments.push(
          `[%csl ${cur.coloredSquares.map((s) => `${s.color}${s.square}`)}]`,
        );
      }
      if (cur.coloredArrows) {
        comments.push(
          `[%cal ${cur.coloredArrows.map((a) => `${a.color}${a.from}${a.to}`)}]`,
        );
      }
      if (cur.comment) {
        comments.push(escapeComment(cur.comment));
      }

      pgn.push(`{${comments.join(' ')}}`);
      comments.splice(0, comments.length);
    }

    // alternatives

    if (cur.alts) {
      cur.alts.forEach((a) => pgn.push(`(${serializeMoveDtos(a, moveFull)})`));
    }
  });

  return pgn.join(' ');
}

/*
    ____,-------------------------------,____
    \   |             Утилиты           |   /
    /___|-------------------------------|___\
*/

// In: 1:29:53, Out: 5393
export function timeToSeconds(timeStr: string): number | undefined {
  const parts = timeStr.split(':');

  if (parts.length > 3) return undefined;

  let seconds = 0;

  const s = parts.pop();
  if (s) seconds += parseInt(s, 10);

  const m = parts.pop();
  if (m) seconds += parseInt(m, 10) * 60;

  const h = parts.pop();
  if (h) seconds += parseInt(h, 10) * 60 * 60;

  return seconds;
}

// In: 5393, Out: 1:29:53
export function secondsToTime(timeSec: number): string {
  const hours = Math.floor(timeSec / 3600);
  const minutes = Math.floor((hours ? timeSec % 3600 : timeSec) / 60);
  const seconds = timeSec % 60;

  return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// In: 3, Out: "!!"
export function nagToSymbol(nag: number): string | null {
  switch (nag) {
    case 1:
      return '!';
    case 2:
      return '?';
    case 3:
      return '!!';
    case 4:
      return '??';
    case 5:
      return '!?';
    case 6:
      return '?!';
    case 7:
    case 8:
      return '⊡';
    case 10:
    case 11:
    case 12:
      return '=';
    case 13:
      return '∞';
    case 14:
      return '⩲';
    case 15:
      return '⩱';
    case 16:
      return '±';
    case 17:
      return '∓';
    case 18:
    case 20:
      return '+−';
    case 19:
    case 21:
      return '−+';
    case 22:
    case 23:
      return '⨀';
    case 32:
    case 33:
      return '⟳';
    case 36:
    case 37:
      return '↑';
    case 40:
    case 41:
      return '→';
    case 132:
    case 133:
      return '⇆';
    case 138:
      return '⨁';
    case 140:
      return '∆';
    case 141:
      return '∇';
    case 142:
      return '⌓';
    case 145:
      return 'RR';
    case 146:
      return 'N';
    case 239:
      return '⇔';
    case 240:
      return '⇗';
    case 242:
      return '⟫';
    case 243:
      return '⟪';
    case 244:
      return '✕';
    case 245:
      return '⊥';
    case 256:
      return '=/∞';
    default:
      return null;
  }
}

// In: ["Rd4", "Gd5"], Out: [{color: "R", square: "d4"}, {color: "G", square: "d5"}]
function mapClsSquares(values: string[]): IColoredSquare[] {
  return values.map((s) => {
    const color = s[0] as IColoredSquare['color'];
    const square = s.substring(1) as IColoredSquare['square'];
    return { color, square };
  });
}

// In: ["Ra8d8", "Ge8c8"], Out: [{color: "R", from: "a8", to: "d8"}, {color: "G", from: "e8", to: "c8"}]
function mapCalSquares(values: string[]): IColoredArrow[] {
  return values.map((a) => {
    const color = a[0] as IColoredArrow['color'];
    const from = a.substring(1, 3) as IColoredArrow['from'];
    const to = a.substring(3) as IColoredArrow['to'];
    return { color, from, to };
  });
}

function mapEval(values: string[]): IEvaluationScore | undefined {
  const [rawScore, rawDepth] = values;
  const mateRx = /[#M]/g;

  if (!rawScore) return undefined;

  const type = rawScore.match(mateRx) ? 'mate' : 'cp';
  const value =
    type === 'mate'
      ? parseInt(rawScore.replace(mateRx, ''), 10)
      : parseFloat(rawScore);
  const depth = rawDepth ? parseInt(rawDepth, 10) : undefined;

  return { type, value, depth };
}

function escapeComment(c: string) {
  return c.replaceAll('}', '');
}
