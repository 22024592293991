import { IPgnMove } from '@libs/chess/pgn';

export const EMPTY_MODEL: IModelMain = { type: 'main', count: 0, moves: [] };

/*
    ____,-------------------------------,____
    \   |           View Модель         |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// 1. Интерфейсы

// Основная ветка
//
export interface IModelMain {
  type: 'main';
  count: number;
  moves: IModelFullMove[];
  moveWithComment?: IPgnMove; // комментарий всей ветки
}

// Ходы основной ветки (full move)
//
export interface IModelFullMove {
  type: 'full-move';
  white: IModelStub | IModelHalfMove;
  black: IModelStub | IModelHalfMove | undefined;
  branches?: IModelVariant[];
}

// Варианты
//
export interface IModelVariant {
  type: 'variant';
  level: number;
  entries: (IModelBlock | IModelVariant)[];
  moveWithComment?: IPgnMove; // комментарий всей ветки
  complex?: boolean; // нельзя инлайнить (т.к. имеет ветвистую вложенность подвариантов)
  branches?: IModelVariant[]; // дерево
}

// Последовательности полуходов
//
export interface IModelBlock {
  type: 'block';
  moves: (IModelHalfMove | IModelStub)[];
}

// Заглушка ходы (троеточие)
//
export interface IModelStub {
  type: 'stub';
  move: {
    color: 'black' | 'white';
    moveFull: number;
  };
}

// Полуход (half move)
//
export interface IModelHalfMove {
  type: 'move';
  move: IPgnMove;
}

export interface IModelMoveMeta {
  score?: { type: 'cp' | 'mate'; value: number };
}

// ------------------------------------------
// 2. Маппинг

// Основная ветка.
//
// Правила:
// - ход разбивается, если у белых есть альтернативные ветки
// - ход разбивается, если у белых есть комментарий
// - ход может начинаться с чёрных, тогда вместо белых — stub
export function mapModel(entries: IPgnMove[], cache?: ModelCache): IModelMain {
  const moves: IModelFullMove[] = [];
  let comment: IPgnMove | undefined;

  let i = 0;
  while (i < entries.length) {
    const m = entries[i]!;
    const mNext = entries[i + 1];
    let fullMove: IModelFullMove;

    const cached = cache?.getFullMove(m, mNext);
    if (cached) {
      fullMove = cached;
    } else {
      const w = m.color === 'white' ? m : undefined;
      const b = m.color === 'black' ? m : mNext;

      const mustBreak = w?.alts || w?.comment;

      // pgn main comment

      const isFirst = i === 0;
      if (isFirst && m.commentBefore !== undefined) {
        comment = m;
      }

      // moves

      const white: IModelHalfMove | IModelStub = w
        ? { type: 'move', move: w }
        : {
            type: 'stub',
            move: { color: 'white', moveFull: m.moveFull },
          };

      const black: IModelHalfMove | IModelStub | undefined = b
        ? mustBreak
          ? {
              type: 'stub',
              move: {
                color: 'black',
                moveFull: b.moveFull,
              },
            }
          : { type: 'move', move: b }
        : undefined;

      // alternatives

      const alts = mustBreak ? w?.alts : b?.alts;
      const branches: IModelVariant[] | undefined = alts?.map((a) =>
        mapVariant(a, 1, cache),
      );

      // full move

      fullMove = {
        type: 'full-move',
        white,
        black,
        branches,
      };
      cache?.setFullMove(m, fullMove);
    }

    moves.push(fullMove);
    const increment =
      fullMove.white.type === 'move' && fullMove.black?.type === 'move' ? 2 : 1;
    i += increment;
  }

  return {
    type: 'main',
    count: entries[entries.length - 1]?.moveFull ?? 0,
    moves,
    moveWithComment: comment,
  };
}

// Побочные ветки.
//
// Правила:
// - ветка, включающая больше 1 подветки — сложная, её нельзя инлайнить
// - ветка, включающая сложную подветку — тоже сложная
// - простые ветки инлайним
// - сложные ветки показываем как дерево
// - сложная ветка разбивается на две при первой сложной ПОДветке: начало и
//   хвост; хвост оригинальной ветки отображается в дереве как первая подветка
function mapVariant(
  entries: IPgnMove[],
  level: number,
  cache?: ModelCache,
): IModelVariant {
  const cached = cache?.getVariant(level, entries);
  if (cached) {
    // FIXME: проверить в сценарии с promotion
    return cached;
  }

  const blocks: IModelVariant['entries'] = [];
  let moves: IModelBlock['moves'] = [];
  let variantComment: IPgnMove | undefined;
  let complex = false;
  let branches: IModelVariant[] | undefined;

  const flush = () => {
    if (!moves.length) return;
    blocks.push({ type: 'block', moves });
    moves = [];
  };

  entries.forEach((m, i) => {
    if (branches) return;

    const isBlack = m.color === 'black';
    const isWhite = !isBlack;
    const isFirst = i === 0;
    const notLast = i < entries.length - 1;

    if (m.commentBefore !== undefined) {
      variantComment = m;
    }

    if (isFirst && isBlack) {
      moves.push({
        type: 'stub',
        move: { color: 'white', moveFull: m.moveFull },
      });
    }

    // проверка isFirst нужна для рекурсивного вызова при парсинге веток.
    // Считаем, что у первого хода варианта не может быть альтернатив (т.к.
    // парсер поднимет их вверх по ирархии вплоть до fullmove, где рекурсия не страшна)
    const alts = isFirst
      ? undefined
      : m.alts?.map((a) => mapVariant(a, level + 1, cache));
    const needBranches =
      alts && (alts.length > 1 || alts.find((a) => a.complex));
    if (needBranches) {
      complex = true;
    }

    if (!needBranches) {
      moves.push({ type: 'move', move: m });
    } else {
      branches = [];
      branches.push(mapVariant(entries.slice(i), level + 1, cache));
      branches.push(...alts);
    }

    const mustStub = alts && !branches && isWhite && notLast;

    if (mustStub) {
      moves.push({
        type: 'stub',
        move: { color: 'black', moveFull: m.moveFull },
      });
    }

    if (alts) {
      flush();
    }

    if (!branches && alts) blocks.push(...alts);

    if (mustStub) {
      moves.push({
        type: 'stub',
        move: { color: 'white', moveFull: m.moveFull },
      });
    }
  });

  flush();

  const variant: IModelVariant = {
    type: 'variant',
    level,
    entries: blocks,
    moveWithComment: variantComment,
    complex,
    branches,
  };

  cache?.setVariant(entries, variant);

  return variant;
}

// ------------------------------------------
// 3. Кэш

// Кэш для воссоздания модели
//
export class ModelCache {
  #fullMoves = new WeakMap<IPgnMove, IModelFullMove>();
  #variants = new WeakMap<IPgnMove[], IModelVariant>();

  getFullMove(m: IPgnMove, next?: IPgnMove): IModelFullMove | null {
    const cached = this.#fullMoves.get(m);

    const noNext = !next || next.color !== 'black';
    const canCache =
      cached &&
      ((noNext && !cached.black) ||
        cached.black?.type === 'stub' ||
        cached.black?.move === next ||
        cached.black?.move === m);

    return canCache ? cached : null;
  }

  setFullMove(m: IPgnMove, f: IModelFullMove): void {
    this.#fullMoves.set(m, f);
  }

  getVariant(level: number, entries: IPgnMove[]): IModelVariant | null {
    const cached = this.#variants.get(entries);
    return cached && cached.level === level ? cached : null;
  }

  setVariant(entries: IPgnMove[], v: IModelVariant) {
    this.#variants.set(entries, v);
  }
}
