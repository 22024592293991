export function scrollToElement({
  container: parent,
  element: child,
  behavior = 'instant',
  offset = 8,
}: {
  container: HTMLElement;
  element: HTMLElement;
  offset?: number;
  behavior?: 'smooth' | 'instant';
}) {
  const boxParent = parent.getBoundingClientRect();
  const boxChild = child.getBoundingClientRect();

  // нужно проскроллить вниз
  if (boxParent.bottom <= boxChild.bottom) {
    parent.scrollTo({
      top: parent.scrollTop + boxChild.bottom - boxParent.bottom + offset,
      behavior: behavior,
    });
  }
  // нужно проскроллить вверх
  else if (boxParent.top >= boxChild.top) {
    parent.scrollTo({
      top: parent.scrollTop + boxChild.top - boxParent.top - offset,
      behavior: behavior,
    });
  }
}
