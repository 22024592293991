import { ReactNode, useState } from 'react';
import { autoUpdate, useFloating, shift, flip } from '@floating-ui/react-dom';
import { cx } from '@libs/classnames';
import { useClickOutside } from '@libs/hooks/useClickOutside';
import { Pagination } from '../Pagination/Pagination';
import styles from './PaginationDropdown.module.css';

export type Props = {
  className?: string;
  variant?: 'play' | 'chessopen';
  label?: string;
  labelInner: string;
  pageTotal: number;
  pageNum: number;
  pageRender?: (pageNum: number) => ReactNode;
  labelRender?: (pageNum: number) => ReactNode;
  maxItems?: number;
  onPage?: (pageNum: number) => void;
};

export function PaginationDropdown({
  className,
  variant = 'play',
  label,
  labelInner,
  pageTotal,
  pageNum,
  pageRender,
  labelRender = pageRender,
  maxItems,
  onPage,
}: Props) {
  const [open, setOpen] = useState(false);
  const ref = useClickOutside<HTMLDivElement>(
    open ? () => setOpen(false) : null,
  );

  const floating = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [shift(), flip()],
  });

  return (
    <div
      className={cx(styles.pagination, className, styles[`is_${variant}`])}
      ref={ref}
    >
      <button
        className={styles.dropdown}
        onClick={() => setOpen(!open)}
        ref={floating.refs.setReference}
      >
        {labelRender ? labelRender(pageNum) : `${label ?? ''}  ${pageNum} `}
        <div className={cx(styles.triangle, { [styles.flipped]: !open })} />
      </button>
      {open && (
        <section
          className={styles.modal}
          ref={floating.refs.setFloating}
          style={floating.floatingStyles}
        >
          {labelInner}{' '}
          <Pagination
            arrows="scrolling"
            pageNum={pageNum}
            pageTotal={pageTotal}
            pageRender={pageRender}
            maxItems={maxItems}
            onPage={(p) => {
              setOpen(false);
              onPage?.(p);
            }}
          />
        </section>
      )}
    </div>
  );
}
