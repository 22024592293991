import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

// Обсчитает квери и вернёт текущее устройство.
//
// ВАЖНО: Media Queries должны быть взаимоисключающими.
//
// Пример:
//
// ```ts
// const device = useCustomMediaQuery(
//    desktop: '(min-width: 1024px)',
//    tablet: '(max-width: 1023px) and (min-width: 500px)',
//    mobile: '(max-width: 499px)',
// }, 'desktop');
// ```
//
export function useCustomMediaQuery<T extends Record<string, string>>(
  devicesWithQueries: T,
  fallback: keyof T,
): keyof T {
  type Device = keyof T;

  const medias = useMemo<Array<{ type: Device; query: MediaQueryList }>>(
    () =>
      Object.keys(devicesWithQueries).map((key) => {
        const device = key as Device;
        return {
          type: device,
          query: matchMedia(devicesWithQueries[device]!),
        };
      }),
    [devicesWithQueries],
  );

  const matchDevice = useCallback(() => {
    const found = medias.find((m) => m.query.matches)?.type;
    return found ?? fallback;
  }, [fallback, medias]);

  const [device, setDevice] = useState<Device>(matchDevice);

  // отслеживание изменений
  useLayoutEffect(() => {
    // обновляем только если есть на что
    const update = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setDevice(matchDevice());
      }
    };

    const abortController = new AbortController();
    medias.forEach((m) => {
      m.query.addEventListener('change', update, {
        signal: abortController.signal,
      });
    });

    return () => abortController.abort();
  }, [matchDevice, medias]);

  return device;
}
