import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TournamentAdditionalScore } from '@features/shared/api/typings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { usePrev } from '@libs/hooks/usePrev';
import { Dropdown } from '@ui/components/Dropdown';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';

const additionalScoreOptions: {
  value: TournamentAdditionalScore | '';
  label: string;
  disabled?: boolean;
}[] = [
  { value: '', label: '' },
  { value: 'Berger', label: 'Коэффициент Бергера' },
  { value: 'ScoreSum', label: 'Сумма очков' },
  { value: 'PartBerger', label: 'Усеч. Бергера', disabled: true },
  { value: 'Match', label: 'Личная встреча' },
];

const swissAdditionalScoreOptions: {
  value: TournamentAdditionalScore | '';
  label: string;
  disabled?: boolean;
}[] = [
  { value: '', label: '' },
  { value: 'Buchholz', label: 'Бухгольца' },
  { value: 'PartBuchholz', label: 'Усеч. Бухгольца' },
  { value: 'Black', label: 'Парт. черными' },
  { value: 'Match', label: 'Личная встреча' },
];

export function AdditionalScoreInput() {
  const { control, setValue } = useFormContext<TournamentEditFormValues>();
  const [scores, mode, system] = useWatch({
    name: ['additionalScoreOrder', 'mode', 'system'],
    control,
  });

  const options =
    system === 'Swiss'
      ? swissAdditionalScoreOptions
      : additionalScoreOptions.map((o) => ({
          ...o,
          disabled:
            o.disabled || (mode === 'Private' && o.value === 'ScoreSum'),
        }));

  const prevScores = usePrev(scores);
  useEffect(() => {
    const existingValues: { [k: string]: number } = {};
    const valuesLeft = new Set(
      options.filter((o) => !!o.value && !o.disabled).map((o) => o.value),
    );
    let changedIdx = -1;
    let duplicateIdx = -1;
    scores.forEach((v: TournamentAdditionalScore, k: number) => {
      valuesLeft.delete(v);
      if (v !== prevScores[k]) {
        changedIdx = k;
      }
      if (v in existingValues && !!v) {
        duplicateIdx = changedIdx === k ? existingValues[v]! : k;
      }
      existingValues[v] = k;
    });

    if (changedIdx === -1 || duplicateIdx === -1) return;

    setValue(
      ('additionalScoreOrder.' + duplicateIdx) as 'additionalScoreOrder.0',
      [...valuesLeft][0] as TournamentAdditionalScore,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores]);

  return (
    <FormRow>
      <FormLabel>Доп. коэффициент</FormLabel>
      <FormInput>
        <Controller
          name="additionalScoreOrder.0"
          control={control}
          render={(field) => (
            <Dropdown
              options={options}
              value={field.field.value}
              onChange={field.field.onChange}
              disabled={field.field.disabled}
            />
          )}
        />
        <Controller
          name="additionalScoreOrder.1"
          control={control}
          render={(field) => (
            <Dropdown
              options={options}
              value={field.field.value}
              onChange={field.field.onChange}
              disabled={field.field.disabled}
            />
          )}
        />
        <Controller
          name="additionalScoreOrder.2"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Dropdown
              options={options}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
        {system === 'Swiss' && (
          <Controller
            name="additionalScoreOrder.3"
            control={control}
            render={({ field: { value, onChange, disabled } }) => (
              <Dropdown
                options={options}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        )}
      </FormInput>
    </FormRow>
  );
}
