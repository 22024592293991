export const MINUTE_IN_MS = 60 * 1000;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;

export function formatSeconds({
  time,
  showHours = false,
}: {
  time: number;
  showHours?: boolean;
}) {
  const minutes = Math.floor((showHours ? time % 3600 : time) / 60);
  const seconds = time % 60;

  let timer = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  if (showHours) {
    const hours = Math.floor(time / 3600);
    timer = `${hours.toString().padStart(2, '0')}:${timer}`;
  }
  return timer;
}
