import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IPgnMove } from '@libs/chess/pgn';
import { IMoveMeta, Props } from '../PgnEditor';
import {
  findMoveById,
  findMovePosition,
  getSimilarAlt,
  updateMoves,
} from '../services/moves';
import { getNavigationMoves } from '../services/navigation';

/*
    ____,-------------------------------,____
    \   |             Хук               |   /
    /___|-------------------------------|___\
*/

export function usePgnEditor(
  externalMoves: IPgnMove[] | undefined,
  {
    readonlyMainVariant = false,
    enableGlobalShortcuts = false,
    initialSelected,
  }: {
    readonlyMainVariant?: boolean;
    enableGlobalShortcuts?: boolean;
    initialSelected?: IPgnMove | null;
  } = {},
) {
  const [moves, setMoves] = useState(externalMoves ?? []);
  const [meta, setMeta] = useState<Record<string, IMoveMeta>>({});

  const [selected, setSelected] = useState<IPgnMove | undefined>(
    initialSelected ?? undefined,
  );
  const [showMenu, setShowMenu] = useState<IPgnMove | undefined>();

  const _store = {
    moves,
    selected,
    readonlyMainVariant,
    saveUndoSnapshot: (moves: IPgnMove[]) => {
      const h = historyRef.current;
      const p = historyPosRef.current;

      if (p === null) {
        h.push({ moves });
      } else if (h[p]?.moves !== moves) {
        historyRef.current = h.slice(0, p + 1).concat({ moves });
        historyPosRef.current = null;
      }
    },
  };
  const ref = useRef(_store);
  ref.current = _store;

  const { first, last, next, prev, playSequence } = useMemo(
    () => getNavigationMoves(selected?.id, moves),
    [selected, moves],
  );

  const movesRef = useRef(moves);
  movesRef.current = moves;
  const lastIdRef = useRef(0);

  // ----- Новые ходы их API

  // TODO: избавиться от двойного рендера из-за setMoves()
  useEffect(() => {
    setMoves((m) => {
      if (m === externalMoves) return m;
      if (!externalMoves && !m.length) return m;

      const selected = ref.current.selected;
      const isLastSelected = selected === m[m.length - 1];

      const nextMoves = externalMoves ? mergeMoves(m, externalMoves) : [];
      const nextSelected = selected
        ? isLastSelected
          ? nextMoves[nextMoves.length - 1]
          : findMoveById(selected.id, nextMoves)
        : undefined;

      setSelected(nextSelected);
      setShowMenu((s) => (s ? nextSelected : undefined));

      if (readonlyMainVariant) {
        historyRef.current = historyRef.current.map((h) => ({
          ...h,
          moves: mergeMoves(h.moves, nextMoves),
        }));
      } else {
        historyRef.current = [];
      }

      return nextMoves;
    });
  }, [externalMoves, readonlyMainVariant]);

  // ----- History (undo + redo)

  const historyRef = useRef<{ moves: IPgnMove[]; selected?: IPgnMove }[]>([
    { moves, selected },
  ]);
  const historyPosRef = useRef<number | null>(null);
  const canUndo = Boolean(
    historyRef.current.length > 1 &&
      (historyPosRef.current === null || historyPosRef.current > 0),
  );
  const canRedo = Boolean(
    historyPosRef.current !== null &&
      historyPosRef.current < historyRef.current.length - 1,
  );
  useEffect(() => {
    const h = historyRef.current;
    const p = historyPosRef.current;
    const cur = p === null ? h[h.length - 1] : h[p];

    if (cur && moves === cur?.moves) {
      cur.selected = selected;
    }
  }, [moves, selected]);

  // ----- Действия

  const addMove = useCallback((san: string, m?: IPgnMove) => {
    const moves = movesRef.current;
    const readonlyMainVariant = ref.current.readonlyMainVariant;

    // невозможно создать подвариант, если нет ходов
    if (readonlyMainVariant && !moves.length) return;

    if (!m) {
      const same = getSimilarAlt(san, moves[0]);
      if (same) {
        setSelected(same);
      } else {
        const newMove: IPgnMove = {
          id: `editor-${lastIdRef.current++}`,
          color: moves[0]?.color === 'black' ? 'black' : 'white',
          moveFull: moves[0]?.moveFull ?? 1,
          san,
        };
        const firstMove = moves[0] ? { ...moves[0] } : null;
        if (firstMove) {
          firstMove.alts = firstMove.alts
            ? [...firstMove.alts, [newMove]]
            : [[newMove]];
        }
        const nextMoves = firstMove
          ? [firstMove, ...moves.slice(1)]
          : [newMove];
        setMoves(nextMoves);
        setSelected(newMove);
        ref.current.saveUndoSnapshot(nextMoves);
      }
    } else {
      const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
        action: 'add',
        readonlyMainVariant,
        moves: [
          {
            id: `editor-${lastIdRef.current++}`,
            color: m.color === 'white' ? 'black' : 'white',
            moveFull: m.color === 'black' ? m.moveFull + 1 : m.moveFull,
            san,
          },
        ],
      });
      setMoves(nextMoves);
      setSelected(nextSelected);
      ref.current.saveUndoSnapshot(nextMoves);
      setShowMenu(undefined);
    }
  }, []);

  const addManyMoves = useCallback((newMoves: IPgnMove[], after?: IPgnMove) => {
    const moves = movesRef.current;
    const readonlyMainVariant = ref.current.readonlyMainVariant;

    // невозможно создать подвариант, если нет ходов
    if (readonlyMainVariant && !moves.length) return;

    // FIXME: реализовать корнер-кейс
    if (!after) return;

    const { nextMoves, nextSelected } = updateMoves(after, ref.current.moves, {
      action: 'add',
      readonlyMainVariant,
      moves: newMoves,
    });
    setMoves(nextMoves);
    setSelected(nextSelected);
    ref.current.saveUndoSnapshot(nextMoves);
    setShowMenu(undefined);
  }, []);

  const updateMoveScore = useCallback(
    (id: string, type: 'cp' | 'mate', value: number) => {
      // обновляем мета-данные только для основной ветки
      const moves = movesRef.current;
      const isMain = moves.find((m) => id === m.id);
      if (!isMain) return;

      setMeta((m) => {
        const prev = m[id];

        // ничего не изменилось
        if (type === prev?.score?.type && value === prev?.score.value) return m;

        const data: IMoveMeta = { ...prev, score: { type, value } };
        return { ...m, [id]: data };
      });
    },
    [],
  );

  // ----- Callbacks

  const onUndo = useCallback(() => {
    const h = historyRef.current;
    const p = historyPosRef.current ?? h.length - 1;

    const prevP = p - 1;
    const prevMoves = h[prevP];
    if (prevMoves) {
      historyPosRef.current = prevP;
      setMoves(prevMoves.moves);
      setSelected(prevMoves.selected);
      setShowMenu(undefined);
    }
  }, []);

  const onRedo = useCallback(() => {
    const h = historyRef.current;
    const p = historyPosRef.current;

    if (p === null) return;

    const nextP = p + 1;
    const nextMoves = h[nextP];
    if (nextMoves) {
      historyPosRef.current = nextP;
      setMoves(nextMoves.moves);
      setSelected(nextMoves.selected);
      setShowMenu(undefined);
    }
  }, []);

  const onMoveSelect = useCallback((id: string | undefined) => {
    if (id) {
      const pos = findMovePosition(id, movesRef.current);
      if (pos) setSelected(pos.cur);
    } else {
      setSelected(undefined);
    }
    setShowMenu(undefined);
  }, []);

  const onMenuOpen = useCallback((id: string) => {
    const pos = findMovePosition(id, movesRef.current);
    if (pos) {
      setSelected(pos.cur);
      setShowMenu(pos.cur);
    }
  }, []);

  const onMenuClose = useCallback(() => {
    setShowMenu(undefined);
  }, []);

  const onMoveDelete = useCallback((m: IPgnMove) => {
    const mustPromote = m.alts && m.alts.length > 1;
    const { nextMoves, nextSelected } = updateMoves(
      m,
      ref.current.moves,
      mustPromote
        ? {
            action: 'promote',
            variant: 0,
          }
        : { action: 'delete' },
    );
    setMoves(nextMoves);
    setSelected(nextSelected);
    ref.current.saveUndoSnapshot(nextMoves);
    setShowMenu(undefined);
  }, []);

  const onMoveCommentCreate = useCallback((m: IPgnMove) => {
    setShowMenu(undefined);

    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { comment: '' },
    });
    setMoves(nextMoves);
    setSelected(nextSelected);
    // sic! пока коммент пустой — не создаём снэпшот
    // ref.current.saveUndoSnapshot(nextMoves, nextSelected);
    setShowMenu(undefined);
  }, []);

  const onMoveCommentUpdate = useCallback((comment: string, m: IPgnMove) => {
    if (m.comment === comment) return;

    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { comment: normalizeComment(comment) },
    });
    setMoves(nextMoves);
    if (ref.current.selected === m) setSelected(nextSelected);
    ref.current.saveUndoSnapshot(nextMoves);
  }, []);

  const onVariantCommentCreate = useCallback((m: IPgnMove) => {
    setShowMenu(undefined);

    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { commentBefore: '' },
    });
    setMoves(nextMoves);
    setSelected(nextSelected);
    // sic! пока коммент пустой — не создаём снэпшот
    // ref.current.saveUndoSnapshot(nextMoves, nextSelected);
    setShowMenu(undefined);
  }, []);

  const onVariantCommentUpdate = useCallback((comment: string, m: IPgnMove) => {
    if (m.commentBefore === comment) return;

    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { commentBefore: normalizeComment(comment) },
    });
    setMoves(nextMoves);
    if (ref.current.selected === m) setSelected(nextSelected);
    ref.current.saveUndoSnapshot(nextMoves);
  }, []);

  const onNagsUpdate = useCallback(
    (nags: number[] | undefined, m: IPgnMove) => {
      const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
        action: 'update',
        data: { nags },
      });
      setMoves(nextMoves);
      setSelected(nextSelected);
      ref.current.saveUndoSnapshot(nextMoves);
      setShowMenu(undefined);
    },
    [],
  );

  // ----- Props

  const props: Props = {
    moves,
    meta,
    //
    selected: selected?.id,
    showMenu: Boolean(showMenu?.id && showMenu.id === selected?.id),
    //
    first: first === null ? null : first?.id,
    last: last?.id,
    next: next?.id,
    prev: prev === null ? null : prev?.id,
    //
    canUndo,
    canRedo,
    //
    onMoveSelect,
    onMenuOpen,
    onMenuClose,
    onMoveDelete,
    onMoveCommentCreate,
    onMoveCommentUpdate,
    onVariantCommentCreate,
    onVariantCommentUpdate,
    onNagsUpdate,
    onUndo,
    onRedo,
  };

  // ----- Global Shortcuts

  const propsRef = useRef(props);
  propsRef.current = props;

  useEffect(() => {
    if (!enableGlobalShortcuts) return;

    function onKeyboard(e: KeyboardEvent) {
      const props = propsRef.current;
      const focused = document.activeElement;

      if (
        focused &&
        ((focused.nodeName === 'INPUT' &&
          !['checkbox', 'radio'].includes(focused.getAttribute('type')!)) ||
          focused.nodeName === 'TEXTAREA' ||
          focused.nodeName === 'SELECT' ||
          focused.getAttribute('contentEditable'))
      )
        return;

      if (e.code === 'ArrowRight' && props.next) {
        props.onMoveSelect?.(props.next);
        e.preventDefault();
      } else if (e.code === 'ArrowLeft' && props.prev !== undefined) {
        props.onMoveSelect?.(props.prev ?? undefined);
        e.preventDefault();
      } else if (e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)) {
        e.shiftKey ? props.onRedo?.() : props.onUndo?.();
        e.preventDefault();
      }
    }

    window.addEventListener('keydown', onKeyboard);

    return () => window.removeEventListener('keydown', onKeyboard);
  }, [enableGlobalShortcuts]);

  // ----- Ahh, profit

  return {
    moves,
    selected,
    first,
    last,
    next,
    prev,
    playSequence,
    addMove,
    addManyMoves,
    updateMoveScore,
    props,
  };
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function normalizeComment(comment?: string) {
  if (!comment || !comment.trim()) return undefined;
  return comment?.replaceAll(/[\r\n}]/g, '');
}

function mergeMoves(movesPrev: IPgnMove[], movesNext: IPgnMove[]): IPgnMove[] {
  const moves: IPgnMove[] = [];
  let diverged = false;
  for (let i = 0; i < movesNext.length; i++) {
    if (movesNext.length < movesPrev.length) {
      return movesNext;
    }

    const prev = movesPrev[i];
    const next = movesNext[i]!;

    if (!diverged && prev && prev.san === next.san) {
      moves.push(prev);
    } else {
      moves.push(next);
      diverged = true;
    }
  }

  return moves;
}
