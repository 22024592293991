import { Chess } from 'chess.js';
import { FEN_STARTING_POSITION } from './constants';
import { IPgnMove } from './pgn';
import { SquareName } from './types';

export type MovesToFenResult = {
  fen: string;
  from?: SquareName;
  to?: SquareName;
  winner?: 'draw' | 'white' | 'black';
};

export function movesToFen(
  initialFen: string = FEN_STARTING_POSITION,
  moves: IPgnMove[] = [],
): MovesToFenResult {
  const chess = new Chess(initialFen);
  moves.forEach((m) => {
    chess.move(m.san);
  });
  const h = chess.history({ verbose: true });
  const m = h[h.length - 1];
  const { from, to } = m ?? {};
  const fen = chess.fen();
  const winner = mapWinner(chess);
  return { fen, from, to, winner };
}

function mapWinner(chess: Chess): MovesToFenResult['winner'] {
  if (!chess.isGameOver()) return undefined;
  if (chess.isDraw()) return 'draw';
  return chess.turn() === 'b' ? 'white' : 'black';
}
