import { useCallback } from 'react';
import { GameParticipantsResult, GameSide } from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { Fraction } from '@ui/components/Fraction';
import { RoundPhoenixIcon } from '@ui/icons/RoundPhoenixIcon';
import styles from './GameResultsBadge.module.css';

export type Props = {
  className?: string;
  gameId?: string | null;
  additionalNumber?: number;
  large?: boolean;
  result?: GameParticipantsResult;
  side?: GameSide;
  score?: number | null;
  dotCentered?: boolean;
  reversed?: boolean;
  onGameClick?: (gameId: string) => void;
};
export function GameResultsBadge({
  className,
  gameId,
  additionalNumber,
  large,
  result,
  side,
  score,
  reversed,
  dotCentered,
  onGameClick,
}: Props) {
  const onClick = useCallback(() => {
    onGameClick && gameId && onGameClick(gameId);
  }, [onGameClick, gameId]);

  return (
    <div
      className={cx(
        styles.gameResultsBadge,
        result && styles[result],
        className,
        {
          [styles.reversed]: reversed,
          [styles.clickable]: onGameClick && gameId,
          [styles.large]: large,
        },
      )}
      onClick={onClick}
    >
      <span>
        {result === 'Skipped' ? (
          <div className={styles.icon}>
            <RoundPhoenixIcon />
          </div>
        ) : result === 'InProgress' ? (
          <span>C</span>
        ) : (
          <Fraction value={score?.toString()} />
        )}
      </span>

      <div className={styles.rightColumn}>
        <span className={styles.additionalNumber}>{additionalNumber}</span>

        <div
          className={cx(styles.sideDot, side && styles[side], {
            [styles.centered]: dotCentered,
          })}
        />
      </div>
    </div>
  );
}
