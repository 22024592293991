export function formatDecimalValuation(
  type: 'p' /* pawn = 100 cp */ | 'cp' /* centipawn  = 0,01 pawn */ | 'mate',
  valuation: number,
  fractionDigits = 2,
): string {
  if (type === 'mate') return `#${valuation}`;
  const val = type === 'p' ? valuation : valuation / 100;
  return val > 0
    ? `+${val.toFixed(fractionDigits)}`
    : val.toFixed(fractionDigits);
}
