import { CardParticipantList } from 'features/shared/ui/CardParticipantList';
import { useEffect } from 'react';
import {
  ParticipantDto,
  ClockControlType,
  TournamentTeam,
} from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { Loader } from '@ui/components/Loader';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Tabs } from '@ui/components/Tabs';
import styles from './TournamentParticipants.module.css';

type Sort = 'Alphabet' | 'rank' | 'RegistrationDate' | 'position';

const desktopTabs: { value: Sort; label: string; disabled?: boolean }[] = [
  { value: 'position', label: 'По порядку' },
  // { value: 'RegistrationDate', label: 'По регистрации' },
  { value: 'rank', label: 'По рейтингу' },
  { value: 'Alphabet', label: 'По алфавиту' },
];
const mobileTabs: { value: Sort; label: string; disabled?: boolean }[] = [
  { value: 'position', label: 'По порядку' },
  { value: 'rank', label: 'По рейтингу' },
  // { value: 'Alphabet', label: 'По алфавиту' },
];

export type Props = {
  className?: string;
  isPending?: boolean;
  teams?: TournamentTeam[];
  participants?: ParticipantDto[];
  clockControl?: ClockControlType;
  onTabChange: (sort: Sort) => void;
  tab: Sort;
  isManager: boolean;
  highlightedUserId?: string;
};
export function TournamentParticipants({
  className,
  isPending,
  teams,
  participants,
  clockControl,
  onTabChange,
  tab,
  isManager,
  highlightedUserId,
}: Props) {
  const isMobile = useMediaQuery() === 'mobile';

  useEffect(() => {
    if (isMobile && tab === 'RegistrationDate') {
      onTabChange('rank');
    }
  }, [isMobile, tab, onTabChange]);

  return (
    <div className={cx(className, styles.participantsWidget)}>
      <div className={styles.title}>
        <PageSubTitle>Участники</PageSubTitle>
        <Tabs
          tabs={isMobile ? mobileTabs : desktopTabs}
          value={tab}
          onChange={onTabChange}
        />
      </div>

      {isPending ? (
        <Loader className={styles.loader} centered />
      ) : (
        <CardParticipantList
          className={styles.participants}
          hideNumbers={tab !== 'position'}
          participants={participants}
          groups={teams?.map((t) => ({ id: t.teamId, name: t.name }))}
          preset="tournament"
          clockControl={clockControl}
          isManager={isManager}
          highlightedUserId={highlightedUserId}
          showEmails={isManager}
        />
      )}
    </div>
  );
}
