import { TournamentSystemView } from 'features/shared/ui/TournamentSystemView';
import { useCallback } from 'react';
import {
  ClockControlSettings,
  ClockControlType,
  ParticipantStatus,
  TournamentEntrance,
  TournamentMode,
  TournamentStatus,
  TournamentSystem,
} from '@features/shared/api/typings';
import { EventEntrance } from '@features/shared/ui/EventEntrance';
import { cx } from '@libs/classnames';
import { Badge } from '@ui/components/Badge';
import { Card } from '@ui/components/Card';
import { DateView } from '@ui/components/DateView';
import styles from './ClubEventCard.module.css';

const PARTICIPANT_STATUS_MAP: { [status in ParticipantStatus]: string } = {
  Invited: 'Приглашен',
  Participating: 'Участвую',
  Exited: 'Вышел',
  Banned: 'Исключен',
  Deleted: 'Удален',
};

export type Props = {
  tournament: {
    tournamentId: string;
    title: string;
    status: TournamentStatus;
    promoted: boolean;
    entrance: TournamentEntrance;
    mode: TournamentMode;
    system: TournamentSystem;
    clockControl?: ClockControlType;
    clockControlSettings?: ClockControlSettings;
    startTimestampMs?: number | null;
    participationStatus?: ParticipantStatus;
  };
  onClick?: (tournamentId: string) => void;
  isClubMember?: boolean;
  isManager?: boolean;
};

export function ClubEventCard({
  tournament,
  onClick,
  isClubMember,
  isManager,
}: Props) {
  const { participationStatus } = tournament;

  const handleClick = useCallback(() => {
    onClick?.(tournament.tournamentId);
  }, [tournament, onClick]);

  let badgeText;
  if (!isManager && isClubMember) {
    if (participationStatus) {
      badgeText = PARTICIPANT_STATUS_MAP[participationStatus];
    } else if (
      tournament.entrance === 'Open' &&
      tournament.status === 'NotStarted'
    ) {
      badgeText = 'Принять участие';
    } else {
      badgeText = 'Смотреть';
    }
  }

  let badgeStyles;
  if (tournament.status === 'InProgress') {
    badgeStyles = styles.inProgress;
  } else if (participationStatus === 'Participating') {
    badgeStyles = styles.participating;
  }

  // FIXME: colored modeConfiguration
  // FIXME: colored preset badges

  return (
    <Card
      active={tournament.promoted}
      disabled={tournament.status === 'Draft'}
      onClick={handleClick}
      className={styles.event}
    >
      <div className={styles.title}>{tournament.title}</div>
      <div className={styles.system}>
        <TournamentSystemView tournament={tournament} />
      </div>
      <div className={styles.entrance}>
        <EventEntrance tournament={tournament} />
      </div>
      <div className={styles.date}>
        {tournament.startTimestampMs ? (
          <>
            <DateView format="d MMMM" date={tournament.startTimestampMs} />
            {tournament.status === 'InProgress' ||
            tournament.status === 'Paused' ? (
              <Badge size="l" className={styles.liveBadge}>
                Идёт
              </Badge>
            ) : (
              <DateView format="HH:mm" date={tournament.startTimestampMs} />
            )}
          </>
        ) : (
          <>
            <span>—</span>
            <span>—</span>
          </>
        )}
      </div>

      {!isManager && badgeText && (
        <div>
          <Badge className={cx(styles.badge, badgeStyles)}>{badgeText}</Badge>
        </div>
      )}
    </Card>
  );
}
