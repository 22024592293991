import { ChessResult, ChessWinner } from './types';

export function resultToWinner(r?: ChessResult | null): ChessWinner | null {
  switch (r) {
    case '1-0':
      return 'white';
    case '0-1':
      return 'black';
    case '1/2-1/2':
      return 'draw';
    default:
      return null;
  }
}

export function resultToText(result?: ChessResult | null): string | null {
  switch (result) {
    case '0-1':
      return 'Победа черных';
    case '1-0':
      return 'Победа белых';
    case '1/2-1/2':
      return 'Ничья';
    default:
      return null;
  }
}

export function winnerToText(result?: ChessWinner | null): string | null {
  switch (result) {
    case 'black':
      return 'Победа черных';
    case 'white':
      return 'Победа белых';
    case 'draw':
      return 'Ничья';
    default:
      return null;
  }
}
