// Навигация
//
// Правила:
// - first и prev по-умолчанию null, что позволяет перевести доску в состояние до 1 хода
// - next и last не дают выйти за пределы текущего варианта
// - prev и first на первом ходе варианта переключают выбор на родительский вариант
// - виртуальные ветки (когда части одного варианта отображаются как ветви
import { IPgnMove } from '@libs/chess/pgn';
import { findMovePosition } from './moves';

//   дерева вместе с настоящими вариантами) требуют дополнительной логики
export function getNavigationMoves(
  selectedId: string | undefined,
  moves: IPgnMove[],
) {
  // Defaults (если нет выбранного)

  let first: IPgnMove | undefined | null = undefined;
  let last: IPgnMove | undefined = moves[moves.length - 1];
  let next: IPgnMove | undefined = moves[0];
  let prev: IPgnMove | undefined | null = undefined;
  let playSequence: IPgnMove[] | undefined;

  // Посик (если есть выбранный)

  if (selectedId) {
    const position = findMovePosition(selectedId, moves);
    const path = position?.path;
    if (!path) {
      console.error(
        'PgnEditor: getNavigationMoves: current selected move not found in moves',
        { selectedId, moves },
      );
      return {};
    }

    playSequence = position.playSequence;

    next = position.next;
    prev = position.prev;

    const { variant } = position;
    const parentVariant =
      variant === moves ? undefined : (path[path.length - 2]?.branch ?? moves);

    first = variant[0] === position.cur ? parentVariant?.[0] : variant[0];
    if (first === moves[0] || variant === moves) first = null;

    last = variant[variant.length - 1];

    // Виртуальные ветки

    if (variant !== moves) {
      const getIsBranched = (m?: IPgnMove) => m?.alts && m.alts.length > 1;

      if (prev && getIsBranched(prev)) {
        first = prev;
      }

      if (getIsBranched(next)) {
        next = undefined;
        last = undefined;
      }

      const virtualLastIndex = variant.findIndex(
        (m, i) => i > position.index && getIsBranched(m),
      );
      if (virtualLastIndex !== -1) {
        last = variant[virtualLastIndex - 1];
      }
    }
  }

  // Нормализация

  if (first === moves[0]) first = null;
  if (last?.id === selectedId) last = undefined;

  return { first, last, next, prev, playSequence };
}
