import { IPgnMove } from '@libs/chess/pgn';

/*
    ____,-------------------------------,____
    \   |      Редактирвоание ходов     |   /
    /___|-------------------------------|___\
*/

type MovePosition = {
  variant: IPgnMove[];
  index: number;
  cur: IPgnMove;
  next?: IPgnMove;
  prev?: IPgnMove | null;
  path: { parent: IPgnMove; branch: IPgnMove[] }[];
  playSequence: IPgnMove[];
};

type Action =
  | UpdateMoveAction
  | AddMoveAction
  | DeleteMoveAction
  | PromoteVariantAction;

// Изменить аттрибуты хода
//
type UpdateMoveAction = {
  action: 'update';
  data: Partial<Omit<IPgnMove, 'alts'>>;
};

// Сделать новый ход из текущей позиции
//
type AddMoveAction = {
  action: 'add';
  readonlyMainVariant?: boolean;
  moves: IPgnMove[];
};

// Удалить ход
//
// ВАЖНО: Удаление первой ветки МОДЕЛИ ведёт к удалению всех веток из-за
// того, что это на самом деле это не подветка, а хвост основной ветки, а её
// соседи — на самом деле её варианты.
// Для таких веток вместо delete следует использовать promote
type DeleteMoveAction = {
  action: 'delete';
};

// Удалить ход и заменить его одним из его вариантов
//
// ВАЖНО: см. комментарий к DeleteMoveAction
type PromoteVariantAction = {
  action: 'promote';
  variant: number;
};

export function updateMoves(
  move: IPgnMove,
  moves: IPgnMove[],
  action: Action,
): { nextMoves: IPgnMove[]; nextSelected?: IPgnMove } {
  let nextMoves = [...moves];
  let nextSelected: IPgnMove | undefined = move;

  const position = findMovePosition(move.id, moves);
  const path = position?.path;
  if (!path) {
    console.error('PgnEditor: updateMove: Move not found', {
      action,
      move,
      moves,
    });
    return { nextMoves, nextSelected };
  }

  // FIXME: разнести этот аццкий метод на несколько action'ов
  // создаём новый мув
  let i = 0;
  let prevParent: IPgnMove | undefined;
  let parent = path[i]?.parent;
  let variant = nextMoves;
  let branch = path[i]?.branch;
  while (parent && branch) {
    const nextVariant = [...branch];
    const nextParent = {
      ...parent,
      alts: parent.alts?.map((a) => (a === branch ? nextVariant : a)),
    };

    variant.splice(variant.indexOf(parent), 1, nextParent);

    i++;
    parent = path[i]?.parent;
    prevParent = nextParent;
    variant = nextVariant;
    branch = path[i]?.branch;
  }

  const index = variant.indexOf(move);

  switch (action.action) {
    case 'update':
      nextSelected = { ...move, ...action.data };
      variant.splice(index, 1, nextSelected);
      break;
    case 'add': {
      // проверяем дубликаты
      const { seq, lastSimilarMove } = filterOutSimilarAlts(
        action.moves,
        moves.slice(position.index + 1),
      );
      if (lastSimilarMove && seq.length === 0) {
        nextSelected = lastSimilarMove;
        nextMoves = moves; // ревертим
        break;
      }

      if (lastSimilarMove) {
        return updateMoves(lastSimilarMove, moves, {
          action: 'add',
          moves: seq,
          readonlyMainVariant: action.readonlyMainVariant,
        });
      }

      // создаём ход
      const newMove: IPgnMove = seq[seq.length - 1]!;
      const newMoves = seq;
      const isMainVariant = !path[0]?.parent;
      let nextMoveIndex = index + 1;
      let nextMove = variant[nextMoveIndex];

      // ВАЖНО: если добавляется новый ход к последнему ходу основной ветки, и
      // запрещено менять основную ветку (как например при тарнсляциях), то
      // создаём новую ветку В САМОМ последнем ходе и дублируем этот ход в
      // самого себя, а уже после добавляем новый.
      // Такой компромис: вместо "1. g4 d5 2. Nc3" получим "1. g4 d5 (1... d5 2. Nc3)"
      if (!nextMove && action.readonlyMainVariant && isMainVariant) {
        nextMoveIndex = index;
        nextMove = move;
        newMoves.unshift({
          ...nextMove,
          alts: undefined,
          id: nextMove.id + '-dupe', // помечаем как дубликат
        });
      }

      if (!nextMove) {
        variant.push(...newMoves);
      } else {
        variant.splice(nextMoveIndex, 1, {
          ...nextMove,
          alts: nextMove.alts ? [...nextMove.alts, newMoves] : [newMoves],
        });
      }
      nextSelected = newMove;
      break;
    }
    // ВАЖНО: см. комментарий к DeleteMoveAction
    case 'delete':
      if (index === 0 && prevParent) {
        prevParent.alts = prevParent.alts?.filter((a) => a !== variant);
        if (prevParent.alts?.length === 0) prevParent.alts = undefined;
      } else {
        const nextVariant = variant.slice(0, index);
        variant.splice(0, variant.length, ...nextVariant);
      }
      nextSelected = position.prev ?? undefined;
      break;
    // ВАЖНО: см. комментарий к DeleteMoveAction
    case 'promote':
      if (move.alts?.[action.variant]) {
        const newTail = move.alts[action.variant]!;
        const newAlts = move.alts.filter((a) => a !== newTail);

        const newMove = newTail[0]!;

        const nextTail = [
          { ...newMove, alts: newAlts.length ? newAlts : undefined },
          ...newTail.slice(1),
        ];
        const nextVariant = variant.slice(0, index);
        nextVariant.push(...nextTail);
        variant.splice(0, variant.length, ...nextVariant);
      } else {
        console.error('PgnEditor: updateMove: invalid variant to promote', {
          action,
          move,
          moves,
        });
      }
      nextSelected = position.prev ?? undefined;
      break;
  }

  return { nextMoves, nextSelected };
}

export function findMovePosition(
  moveId: string,
  moves: IPgnMove[],
  _prevMove: IPgnMove | null = null,
): MovePosition | undefined {
  for (let i = 0; i < moves.length; i++) {
    const m = moves[i]!;

    if (m.id === moveId) {
      return {
        variant: moves,
        index: i,
        cur: m,
        next: moves[i + 1],
        prev: moves[i - 1] ?? _prevMove,
        path: [],
        playSequence: moves.slice(0, i + 1),
      };
    }

    if (m.alts) {
      for (const a of m.alts) {
        const r = findMovePosition(moveId, a, moves[i - 1]);
        if (r) {
          return {
            ...r,
            playSequence: [...moves.slice(0, i), ...r.playSequence],
            path: r.path.length
              ? [{ parent: m, branch: a }, ...r.path]
              : [{ parent: m, branch: a }],
          };
        }
      }
    }
  }
}

export function getSimilarAlt(san: string, move?: IPgnMove) {
  if (!move) return undefined;
  if (move.san === san) return move;
  return move.alts?.find((a) => a[0]?.san === san)?.[0];
}

export function findMoveById(
  id: string,
  moves: IPgnMove[],
): IPgnMove | undefined {
  for (let i = 0; i < moves.length; i++) {
    const m = moves[i]!;
    if (id === m.id) return m;

    if (m.alts) {
      for (let j = 0; j < m.alts.length; j++) {
        const q = findMoveById(id, m.alts[j]!);
        if (q) return q;
      }
    }
  }
  return undefined;
}

function filterOutSimilarAlts(
  seq: IPgnMove[],
  moves: IPgnMove[],
): { seq: IPgnMove[]; moves: IPgnMove[]; lastSimilarMove?: IPgnMove } {
  // проверим основной вариант
  let foundIndex: number | null = null;
  let i = 0;
  while (seq[i] && seq[i]!.san === moves[i]?.san) foundIndex = i++;

  const lastSimilarMove = foundIndex === null ? undefined : moves[foundIndex];
  const seqTail = foundIndex === null ? seq : seq.slice(foundIndex + 1);
  const movesTail = foundIndex === null ? moves : moves.slice(foundIndex + 1);

  // поищем в альтернативах
  const alts = movesTail[0]?.alts;
  if (seqTail.length && alts) {
    for (const alt of alts) {
      const r = filterOutSimilarAlts(seqTail, alt);
      if (r.lastSimilarMove) return r;
    }
  }

  return { seq: seqTail, moves: movesTail, lastSimilarMove };
}
