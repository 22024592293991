import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { pluralize } from '@libs/i18n';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';

const roundsPluralConfig = {
  many: 'туров',
  few: 'тура',
  one: 'тур',
  zero: 'туров',
};

export function SwissRoundsCount() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Количество туров</FormLabel>
      <FormInput>
        <Controller
          name="swissSettings.roundsCount"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Input
              invisible
              type="number"
              rightLabel={pluralize(roundsPluralConfig, value)}
              value={value.toString()}
              onChange={onChange}
              min="1"
              max="23"
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
