import { ReactNode, useLayoutEffect } from 'react';
import { useCustomMediaQuery } from '@libs/hooks/useCustomMediaQuery';
import { Device, MediaQueryContext } from './MediaQueryContext';

const MEDIA_QUERIES: Record<Device, string> = {
  desktop: '(min-width: 1024px)',
  tablet: '(max-width: 1023px) and (min-width: 500px)',
  mobile: '(max-width: 499px)',
};

export function MediaQueryProvider({
  children,
  mediaQueries = MEDIA_QUERIES,
}: {
  children: ReactNode;
  /** ВАЖНО: media queries должны быть взаимоисключающими */
  mediaQueries?: Record<Device, string>;
}) {
  const device = useCustomMediaQuery(mediaQueries, 'desktop');

  useLayoutEffect(() => {
    document.body.classList.remove(...Object.keys(mediaQueries));
    document.body.classList.add(device);
  }, [mediaQueries, device]);

  return (
    <MediaQueryContext.Provider value={device}>
      {children}
    </MediaQueryContext.Provider>
  );
}
